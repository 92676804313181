import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import ArrowLeft from "../../assets/svgs/arrow-left2.svg"
import Notification from "../../assets/svgs/notification.svg"
import ngn from "../../assets/png/ngn.png";
import us from "../../assets/png/us.png";
import europe from "../../assets/png/europe.png";
import Plus from "../../assets/svgs/white-plus.svg"
import Add from "../../assets/svgs/add.svg"
import Swap from "../../assets/svgs/arrow-swap.svg"
import content from "../../assets/svgs/Content.svg"
import BlueUp from "../../assets/svgs/blue-arrow-up.svg"

function DashboadTop() {

  const navigate = useNavigate();
  const HandleRoutes = () => {
    navigate("/home")
  }
  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <img
          onClick={HandleRoutes}
          src={ArrowLeft}
          alt="arrow left icon"
          className="hover:bg-slate-300 rounded-full p-2 cursor-pointer"
        />
        <img
          src={Notification}
          alt="notification icon"
          className="hover:bg-slate-300 rounded-full p-2 cursor-pointer"
        />
      </div>

      <div className="w-full flex justify-between items-center my-5">
        <div className="w-4/12 h-10 flex justify-between items-center">
          <div className="w-[30%] h-full rounded-md border border-[#8F90A6] flex items-center justify-between px-3 cursor-pointer">
            <img src={ngn} alt="nigeria icon" className="h-5" />
            <p className="text-[#000] text-[14px] not-italic font-bold leading-normal">
              Naira
            </p>
          </div>

          <div className="w-[30%] h-full rounded-md border border-[#8F90A6] flex items-center justify-between px-3 cursor-pointer">
            <img src={us} alt="united state icon" className="h-5" />
            <p className="text-[#000] text-[14px] not-italic font-bold leading-normal">
              USD
            </p>
          </div>

          <div className="w-[30%] h-full rounded-md border border-[#8F90A6] flex items-center justify-between px-3 cursor-pointer">
            <img src={europe} alt="europe icon" className="h-5" />
            <p className="text-[#000] text-[14px] not-italic font-bold leading-normal">
              EUR
            </p>
          </div>
        </div>

        <Link
          to=""
          className="bg-[#2F80EC] flex items-center justify-center self-stretch py-3 px-5 rounded-md text-[#fff]"
        >
          <img src={Plus} alt="plus icon" />
          <p className="text-[14px] not-italic font-medium leading-normal text-right">
            Create New Account
          </p>
        </Link>
      </div>

      <div className="w-full flex justify-between items-center mt-10">
        <div className="w-2/4">
          <p className="text-[#828282] text-[16px] not-italic font-light leading-normal">
            Naira Total balance
          </p>
          <h1 className="text-[#0E0E0E] text-[48.83px] not-italic font-bold leading-normal">
            ₦0.00
          </h1>
        </div>

        <div className="w-2/4 h-10 flex justify-between items-center">
          <Link to="/home/naira/new-recipient"
            className="w-[20%] h-full rounded-md border border-[#2F80EC] flex items-center justify-center py-3 px-5 cursor-pointer"
          >
            <img src={Add} alt="add icon" />
            <p className="text-[#2F80EC] text-[14px] not-italic font-normal leading-normal">
              Add
            </p>
          </Link>

          <div
            className="w-[20%] h-full rounded-md border border-[#2F80EC] flex items-center justify-center py-3 px-5 cursor-pointer"
          >
            <img src={BlueUp} alt="add icon" />
            <p className="text-[#2F80EC] text-[14px] not-italic font-normal leading-normal">
              Send
            </p>
          </div>

          <div
            className="w-[20%] h-full rounded-md border border-[#2F80EC] flex items-center justify-center py-3 px-5 cursor-pointer"
          >
            <img src={Add} alt="add icon" />
            <p className="text-[#2F80EC] text-[14px] not-italic font-normal leading-normal">
              Request
            </p>
          </div>

          <div className="w-[20%] h-full rounded-md border border-[#2F80EC] flex items-center justify-center py-3 px-5 cursor-pointer">
            <img src={Swap} alt="swap icon" />
            <p className="text-[#2F80EC] text-[14px] not-italic font-normal leading-normal">
              Convert
            </p>
          </div>

          <div className="w-[8%] h-full rounded-md border border-[#2F80EC] flex justify-center items-center cursor-pointer">
            <img src={content} alt="swap icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboadTop