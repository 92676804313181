


export const FolderIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9V5C2 3.9 2.9 3 4 3H7.93C8.25941 3.0017 8.58331 3.08475 8.8729 3.24176C9.1625 3.39877 9.40882 3.62488 9.59 3.9L10.41 5.1C10.5912 5.37512 10.8375 5.60123 11.1271 5.75824C11.4167 5.91525 11.7406 5.9983 12.07 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V17M2 13H12M12 13L9 16M12 13L9 10"
        stroke="#9D9D9D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export const CircleCheck = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 12L11 14L15 10M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
        stroke="#9D9D9D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export const ArrowDown = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9L12 15L18 9"
        stroke="#050505"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export const RotateIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00004 11.875C3.40004 11.875 1.29004 9.76001 1.29004 7.16501C1.29004 6.23001 1.56504 5.32501 2.08504 4.54501C2.20004 4.37501 2.43504 4.32501 2.60504 4.44001C2.77504 4.55501 2.82504 4.79001 2.71004 4.96001C2.27504 5.61001 2.04504 6.37501 2.04504 7.16001C2.04504 9.34501 3.82004 11.12 6.00504 11.12C8.19004 11.12 9.96504 9.34501 9.96504 7.16001C9.96504 4.97501 8.18504 3.20001 6.00004 3.20001C5.54004 3.20001 5.09004 3.26501 4.66504 3.39501C4.46504 3.45501 4.25504 3.34501 4.19504 3.14501C4.13504 2.94501 4.24504 2.73501 4.44504 2.67501C4.94504 2.52501 5.46504 2.44501 6.00004 2.44501C8.60004 2.44501 10.71 4.56001 10.71 7.15501C10.71 9.75001 8.60004 11.875 6.00004 11.875Z"
        fill="#050505"
      />
      <path
        d="M3.93494 3.535C3.84994 3.535 3.75994 3.505 3.68994 3.445C3.52994 3.305 3.51494 3.07 3.64994 2.915L5.09493 1.255C5.22993 1.1 5.46994 1.08 5.62494 1.22C5.77994 1.355 5.79493 1.595 5.65993 1.75L4.21494 3.405C4.13994 3.49 4.03494 3.535 3.93494 3.535Z"
        fill="#050505"
      />
      <path
        d="M5.62004 4.765C5.54504 4.765 5.46504 4.74 5.40004 4.695L3.71004 3.46C3.54504 3.34 3.51004 3.105 3.63004 2.94C3.75004 2.77 3.98504 2.735 4.15504 2.855L5.84004 4.085C6.00504 4.205 6.04504 4.44 5.92004 4.61C5.85004 4.715 5.73504 4.765 5.62004 4.765Z"
        fill="#292D32"
      />
    </svg>
  );
}
export const CopyIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.7 11.875H3.8C1.605 11.875 0.625 10.895 0.625 8.7V6.8C0.625 4.605 1.605 3.625 3.8 3.625H5.3C5.505 3.625 5.675 3.795 5.675 4C5.675 4.205 5.505 4.375 5.3 4.375H3.8C2.01 4.375 1.375 5.01 1.375 6.8V8.7C1.375 10.49 2.01 11.125 3.8 11.125H5.7C7.49 11.125 8.125 10.49 8.125 8.7V7.2C8.125 6.995 8.295 6.825 8.5 6.825C8.705 6.825 8.875 6.995 8.875 7.2V8.7C8.875 10.895 7.895 11.875 5.7 11.875Z"
        fill="#050505"
      />
      <path
        d="M8.50005 7.57499H6.90005C5.49505 7.57499 4.92505 7.00499 4.92505 5.59999V3.99999C4.92505 3.84999 5.01505 3.70999 5.15505 3.65499C5.29505 3.59499 5.45505 3.62999 5.56505 3.73499L8.76505 6.93499C8.87005 7.03999 8.90505 7.20499 8.84505 7.34499C8.79005 7.48499 8.65005 7.57499 8.50005 7.57499ZM5.67505 4.90499V5.59999C5.67505 6.59499 5.90505 6.82499 6.90005 6.82499H7.59505L5.67505 4.90499Z"
        fill="#050505"
      />
      <path
        d="M7.80005 1.875H5.80005C5.59505 1.875 5.42505 1.705 5.42505 1.5C5.42505 1.295 5.59505 1.125 5.80005 1.125H7.80005C8.00505 1.125 8.17505 1.295 8.17505 1.5C8.17505 1.705 8.00505 1.875 7.80005 1.875Z"
        fill="#050505"
      />
      <path
        d="M3.5 3.375C3.295 3.375 3.125 3.205 3.125 3C3.125 1.965 3.965 1.125 5 1.125H6.31C6.515 1.125 6.685 1.295 6.685 1.5C6.685 1.705 6.515 1.875 6.31 1.875H5C4.38 1.875 3.875 2.38 3.875 3C3.875 3.205 3.705 3.375 3.5 3.375Z"
        fill="#050505"
      />
      <path
        d="M9.59497 9.375C9.38997 9.375 9.21997 9.205 9.21997 9C9.21997 8.795 9.38997 8.625 9.59497 8.625C10.165 8.625 10.625 8.16 10.625 7.595V4.5C10.625 4.295 10.795 4.125 11 4.125C11.205 4.125 11.375 4.295 11.375 4.5V7.595C11.375 8.575 10.575 9.375 9.59497 9.375Z"
        fill="#050505"
      />
      <path
        d="M11 4.87499H9.5C8.17 4.87499 7.625 4.32999 7.625 2.99999V1.49999C7.625 1.34999 7.715 1.20999 7.855 1.15499C7.995 1.09499 8.155 1.12999 8.265 1.23499L11.265 4.23499C11.37 4.33999 11.405 4.50499 11.345 4.64499C11.29 4.78499 11.15 4.87499 11 4.87499ZM8.375 2.40499V2.99999C8.375 3.91499 8.585 4.12499 9.5 4.12499H10.095L8.375 2.40499Z"
        fill="#050505"
      />
    </svg>
  );
}
export const SendIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.452637"
        width="26.5472"
        height="26.5472"
        rx="13.2736"
        fill="black"
      />
      <path
        d="M18.9439 8.06608C18.6672 7.78214 18.2578 7.67636 17.876 7.78771L8.97354 10.3765C8.57074 10.4884 8.28524 10.8097 8.20834 11.2178C8.12977 11.6331 8.4042 12.1603 8.76274 12.3808L11.5464 14.0917C11.8319 14.267 12.2004 14.223 12.4366 13.9848L15.6241 10.7774C15.7846 10.6104 16.0502 10.6104 16.2106 10.7774C16.3711 10.9388 16.3711 11.2005 16.2106 11.3675L13.0176 14.5755C12.7808 14.8132 12.7365 15.1834 12.9108 15.4707L14.6116 18.2822C14.8108 18.6163 15.1538 18.8055 15.5301 18.8055C15.5743 18.8055 15.6241 18.8055 15.6684 18.8C16.1 18.7443 16.443 18.4492 16.5703 18.0317L19.2095 9.14058C19.3257 8.762 19.2205 8.35001 18.9439 8.06608Z"
        fill="#D5FD89"
      />
    </svg>
  );
}
export const MainLogo = () => {
  return (
    <svg
      width="130"
      height="160"
      viewBox="0 0 670 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M208.357 63.3321C211.5 57.3247 216.037 52.9523 221.981 50.2147C227.925 47.4772 236.087 46.1211 246.467 46.1211V70.7209C234.984 71.4053 226.037 72.9388 219.624 75.3342C213.198 77.7295 208.42 81.6204 205.277 87.0194C202.134 92.4185 200.36 100.099 199.954 110.073V153.734H170.855V47.9588H199.143L199.967 88.5403H201.196C202.831 77.7422 205.226 69.3395 208.369 63.3321H208.357Z"
        fill="#050505"
      />
      <path
        d="M273.069 106.777C273.209 115.522 275.934 122.417 281.269 127.474C286.592 132.53 293.423 135.053 301.763 135.053C308.315 135.053 313.892 133.38 318.467 130.034C323.042 126.688 326.02 122.353 327.377 117.018H352.787C351.698 125.623 348.554 132.936 343.358 138.943C338.162 144.951 331.876 149.463 324.5 152.466C317.123 155.47 309.544 156.978 301.75 156.978C284.26 156.978 270.459 152.061 260.345 142.226C250.231 132.391 245.174 118.45 245.174 100.415C245.174 89.3509 247.43 79.5794 251.942 71.1133C256.454 62.6472 262.804 56.1202 271.004 51.545C279.203 46.9697 288.836 44.6758 299.9 44.6758C310.964 44.6758 320.393 47.0711 328.593 51.8491C336.793 56.6272 343.041 63.0908 347.35 71.2147C351.66 79.3513 353.801 88.4004 353.801 98.3746V106.777H273.057H273.069ZM273.069 90.9985H325.133C324.588 83.3435 321.952 77.3742 317.237 73.0651C312.523 68.756 306.541 66.6141 299.304 66.6141C292.067 66.6141 285.845 68.7306 281.066 72.9637C276.288 77.1967 273.614 83.2168 273.069 90.9985Z"
        fill="#050505"
      />
      <path
        d="M385.409 106.777C385.548 115.522 388.273 122.417 393.609 127.474C398.932 132.53 405.763 135.053 414.102 135.053C420.654 135.053 426.231 133.38 430.806 130.034C435.381 126.688 438.36 122.353 439.716 117.018H465.127C464.037 125.623 460.894 132.936 455.698 138.943C450.501 144.951 444.215 149.463 436.839 152.466C429.463 155.47 421.884 156.978 414.089 156.978C396.6 156.978 382.798 152.061 372.684 142.226C362.571 132.391 357.514 118.45 357.514 100.415C357.514 89.3509 359.77 79.5794 364.281 71.1133C368.793 62.6472 375.143 56.1202 383.343 51.545C391.543 46.9697 401.175 44.6758 412.239 44.6758C423.303 44.6758 432.733 47.0711 440.933 51.8491C449.132 56.6272 455.381 63.0908 459.69 71.2147C463.999 79.3513 466.141 88.4004 466.141 98.3746V106.777H385.396H385.409ZM385.409 90.9985H437.473C436.928 83.3435 434.292 77.3742 429.577 73.0651C424.862 68.756 418.88 66.6141 411.643 66.6141C404.407 66.6141 398.184 68.7306 393.406 72.9637C388.628 77.1967 385.954 83.2168 385.409 90.9985Z"
        fill="#050505"
      />
      <path
        d="M475.013 10.2539H504.112V153.721H475.013V10.2539Z"
        fill="#050505"
      />
      <path
        d="M515.671 17.0082C515.671 12.3569 517.344 8.3647 520.69 5.01882C524.036 1.67294 528.028 0 532.679 0C537.33 0 541.525 1.63492 544.871 4.91743C548.217 8.19994 549.89 12.2302 549.89 17.0082C549.89 21.7862 548.217 25.6137 544.871 28.8962C541.525 32.1787 537.457 33.8137 532.679 33.8137C527.901 33.8137 524.036 32.1787 520.69 28.8962C517.344 25.6137 515.671 21.6595 515.671 17.0082ZM518.332 47.9576H547.431V153.708H518.332V47.9576Z"
        fill="#050505"
      />
      <path
        d="M664.664 63.5344C668.213 69.6812 670 76.9939 670 85.46V153.708H640.698V94.2683C640.698 85.8022 638.581 79.2372 634.348 74.5986C630.115 69.9473 624.234 67.628 616.719 67.628C608.658 67.628 602.334 70.3275 597.759 75.7266C593.184 81.1256 590.89 88.6665 590.89 98.3746V153.708H561.588V47.9583H589.876L590.902 80.137H592.132C593.64 69.339 598.038 60.7335 605.351 54.3079C612.663 47.8822 621.167 44.6758 630.863 44.6758C637.973 44.6758 644.487 46.2854 650.431 49.4918C656.375 52.6983 661.128 57.3876 664.677 63.5344H664.664Z"
        fill="#050505"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M143.138 88.2876C143.138 127.814 111.095 159.857 71.5689 159.857C32.0425 159.857 0 127.814 0 88.2876C0 48.7612 32.0425 16.7188 71.5689 16.7188C111.095 16.7188 143.138 48.7612 143.138 88.2876ZM99.4899 66.2734H66.2973V83.2454H49.8467V125.563H82.7478V88.2878H99.4899V66.2734Z"
        fill="#050505"
      />
    </svg>
  );
}

export const WhiteCancel = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="#F2F4F5"
      />
      <path
        d="M9.16937 15.5804C8.97937 15.5804 8.78938 15.5104 8.63938 15.3604C8.34938 15.0704 8.34938 14.5904 8.63938 14.3004L14.2994 8.64035C14.5894 8.35035 15.0694 8.35035 15.3594 8.64035C15.6494 8.93035 15.6494 9.41035 15.3594 9.70035L9.69937 15.3604C9.55937 15.5104 9.35937 15.5804 9.16937 15.5804Z"
        fill="#F2F4F5"
      />
      <path
        d="M14.8294 15.5804C14.6394 15.5804 14.4494 15.5104 14.2994 15.3604L8.63938 9.70035C8.34938 9.41035 8.34938 8.93035 8.63938 8.64035C8.92937 8.35035 9.40937 8.35035 9.69937 8.64035L15.3594 14.3004C15.6494 14.5904 15.6494 15.0704 15.3594 15.3604C15.2094 15.5104 15.0194 15.5804 14.8294 15.5804Z"
        fill="#F2F4F5"
      />
    </svg>
  );
}
export const VersusPayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M4.17315 14.0548L4.17315 14.0548L0.722855 5.02973C0.722841 5.02969 0.722828 5.02965 0.722814 5.02962C-0.112924 2.84485 1.50098 0.50001 3.84028 0.5L4.17315 14.0548ZM4.17315 14.0548C4.56065 15.0683 5.24664 15.9402 6.14042 16.5554C7.0342 17.1706 8.09365 17.5 9.17867 17.5H9.17872M4.17315 14.0548L9.17872 17.5M9.17872 17.5C9.93139 17.4999 10.6664 17.2722 11.2872 16.8466C11.9081 16.4211 12.3856 15.8177 12.6571 15.1157L12.6572 15.1156L16.5361 5.08201L16.5362 5.08195C17.3889 2.8755 15.7607 0.5 13.3946 0.5H13.3946H12.8946V0.53885C12.3912 0.617533 11.9109 0.81429 11.4945 1.11596C10.9422 1.51606 10.5302 2.08028 10.3173 2.7282C10.3172 2.72859 10.3171 2.72898 10.3169 2.72937L8.94766 6.82956L8.88358 7.02145L8.97096 7.2039L10.0077 9.36869L10.5427 10.4858L10.9332 9.31034L12.7498 3.84116L12.7505 3.8392C12.8154 3.64098 12.9413 3.46834 13.1102 3.34593C13.279 3.22359 13.4821 3.15767 13.6906 3.15758C13.773 3.15763 13.8542 3.17774 13.927 3.21617C13.9999 3.25461 14.0624 3.31022 14.109 3.37819C14.1556 3.44617 14.1849 3.52447 14.1945 3.60632C14.204 3.68809 14.1936 3.77095 14.164 3.84776C14.1639 3.84784 14.1639 3.84792 14.1639 3.84801L10.8436 12.436L7.27655 2.88459L7.27654 2.88456M9.17872 17.5L7.27654 2.88456M7.27654 2.88456C7.01499 2.1843 6.54572 1.58068 5.9316 1.15451C5.31747 0.728343 4.5878 0.49998 3.8403 0.5L7.27654 2.88456ZM4.7311 3.66876L4.73123 3.66911L8.8731 14.7566C8.46475 14.6624 8.07546 14.4919 7.72657 14.2517C7.16789 13.8671 6.73908 13.322 6.49684 12.6885C6.49683 12.6885 6.49683 12.6885 6.49683 12.6884L3.26143 4.22553L3.26127 4.22512C3.21563 4.10605 3.19961 3.97766 3.21461 3.85103C3.2296 3.72439 3.27515 3.6033 3.34733 3.49818C3.41952 3.39305 3.51617 3.30705 3.62898 3.24758C3.74169 3.18816 3.86714 3.157 3.99455 3.15678C4.15478 3.15691 4.31115 3.20597 4.44275 3.29739C4.57448 3.38889 4.67509 3.51847 4.7311 3.66876Z"
        stroke="#828282"
      />
    </svg>
  );
}
export const UploadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M6.75008 13.3124C6.44258 13.3124 6.18758 13.0574 6.18758 12.7499V9.60737L5.64758 10.1474C5.43008 10.3649 5.07008 10.3649 4.85258 10.1474C4.63508 9.92987 4.63508 9.56987 4.85258 9.35237L6.35258 7.85237C6.51008 7.69487 6.75758 7.64237 6.96758 7.73237C7.17758 7.81487 7.31258 8.02487 7.31258 8.24987V12.7499C7.31258 13.0574 7.05758 13.3124 6.75008 13.3124Z"
        fill="#828282"
      />
      <path
        d="M8.25008 10.3126C8.10758 10.3126 7.96508 10.2601 7.85258 10.1476L6.35258 8.64758C6.13508 8.43008 6.13508 8.07008 6.35258 7.85258C6.57008 7.63508 6.93008 7.63508 7.14758 7.85258L8.64758 9.35258C8.86508 9.57008 8.86508 9.93008 8.64758 10.1476C8.53508 10.2601 8.39258 10.3126 8.25008 10.3126Z"
        fill="#828282"
      />
      <path
        d="M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H10.5C10.8075 0.9375 11.0625 1.1925 11.0625 1.5C11.0625 1.8075 10.8075 2.0625 10.5 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V7.5C15.9375 7.1925 16.1925 6.9375 16.5 6.9375C16.8075 6.9375 17.0625 7.1925 17.0625 7.5V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z"
        fill="#828282"
      />
      <path
        d="M16.5 8.06237H13.5C10.935 8.06237 9.9375 7.06487 9.9375 4.49987V1.49987C9.9375 1.27487 10.0725 1.06487 10.2825 0.982369C10.4925 0.892369 10.7325 0.94487 10.8975 1.10237L16.8975 7.10237C17.055 7.25987 17.1075 7.50737 17.0175 7.71737C16.9275 7.92737 16.725 8.06237 16.5 8.06237ZM11.0625 2.85737V4.49987C11.0625 6.43487 11.565 6.93737 13.5 6.93737H15.1425L11.0625 2.85737Z"
        fill="#828282"
      />
    </svg>
  );
}

export const BankLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M13.3425 17.0547H4.6575C2.6025 17.0547 0.9375 15.3822 0.9375 13.3272V7.76967C0.9375 6.74967 1.5675 5.46717 2.3775 4.83717L6.42 1.68717C7.635 0.742168 9.5775 0.697168 10.8375 1.58217L15.4725 4.82967C16.365 5.45217 17.0625 6.78717 17.0625 7.87467V13.3347C17.0625 15.3822 15.3975 17.0547 13.3425 17.0547ZM7.11 2.57217L3.0675 5.72217C2.535 6.14217 2.0625 7.09467 2.0625 7.76967V13.3272C2.0625 14.7597 3.225 15.9297 4.6575 15.9297H13.3425C14.775 15.9297 15.9375 14.7672 15.9375 13.3347V7.87467C15.9375 7.15467 15.42 6.15717 14.8275 5.75217L10.1925 2.50467C9.3375 1.90467 7.9275 1.93467 7.11 2.57217Z"
        fill="#2F80EC"
      />
      <path
        d="M5.62508 12.9376C5.48258 12.9376 5.34008 12.8851 5.22758 12.7726C5.01008 12.5551 5.01008 12.1951 5.22758 11.9776L7.62758 9.57758C7.74758 9.45758 7.90508 9.39758 8.07758 9.41258C8.24258 9.42758 8.39258 9.51758 8.49008 9.66008L9.30758 10.8901L11.9701 8.22758C12.1876 8.01008 12.5476 8.01008 12.7651 8.22758C12.9826 8.44508 12.9826 8.80508 12.7651 9.02258L9.61508 12.1726C9.49508 12.2926 9.33758 12.3526 9.16508 12.3376C9.00008 12.3226 8.85008 12.2326 8.75258 12.0901L7.93508 10.8601L6.02258 12.7726C5.91008 12.8851 5.76758 12.9376 5.62508 12.9376Z"
        fill="#2F80EC"
      />
      <path
        d="M12.375 10.6875C12.0675 10.6875 11.8125 10.4325 11.8125 10.125V9.1875H10.875C10.5675 9.1875 10.3125 8.9325 10.3125 8.625C10.3125 8.3175 10.5675 8.0625 10.875 8.0625H12.375C12.6825 8.0625 12.9375 8.3175 12.9375 8.625V10.125C12.9375 10.4325 12.6825 10.6875 12.375 10.6875Z"
        fill="#2F80EC"
      />
    </svg>
  );
}
export const NavSettings = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#EDEBEB" />
      <path
        d="M36.5 18.0625H29C28.4875 18.0625 28.0625 17.6375 28.0625 17.125C28.0625 16.6125 28.4875 16.1875 29 16.1875H36.5C37.0125 16.1875 37.4375 16.6125 37.4375 17.125C37.4375 17.6375 37.0125 18.0625 36.5 18.0625Z"
        fill="#0E0E0E"
      />
      <path
        d="M16.5 18.0625H11.5C10.9875 18.0625 10.5625 17.6375 10.5625 17.125C10.5625 16.6125 10.9875 16.1875 11.5 16.1875H16.5C17.0125 16.1875 17.4375 16.6125 17.4375 17.125C17.4375 17.6375 17.0125 18.0625 16.5 18.0625Z"
        fill="#0E0E0E"
      />
      <path
        d="M21.5 22.4375C18.575 22.4375 16.1875 20.05 16.1875 17.125C16.1875 14.2 18.575 11.8125 21.5 11.8125C24.425 11.8125 26.8125 14.2 26.8125 17.125C26.8125 20.05 24.425 22.4375 21.5 22.4375ZM21.5 13.6875C19.6 13.6875 18.0625 15.225 18.0625 17.125C18.0625 19.025 19.6 20.5625 21.5 20.5625C23.4 20.5625 24.9375 19.025 24.9375 17.125C24.9375 15.225 23.4 13.6875 21.5 13.6875Z"
        fill="#0E0E0E"
      />
      <path
        d="M36.5 31.8125H31.5C30.9875 31.8125 30.5625 31.3875 30.5625 30.875C30.5625 30.3625 30.9875 29.9375 31.5 29.9375H36.5C37.0125 29.9375 37.4375 30.3625 37.4375 30.875C37.4375 31.3875 37.0125 31.8125 36.5 31.8125Z"
        fill="#0E0E0E"
      />
      <path
        d="M19 31.8125H11.5C10.9875 31.8125 10.5625 31.3875 10.5625 30.875C10.5625 30.3625 10.9875 29.9375 11.5 29.9375H19C19.5125 29.9375 19.9375 30.3625 19.9375 30.875C19.9375 31.3875 19.5125 31.8125 19 31.8125Z"
        fill="#0E0E0E"
      />
      <path
        d="M26.5 36.1875C23.575 36.1875 21.1875 33.8 21.1875 30.875C21.1875 27.95 23.575 25.5625 26.5 25.5625C29.425 25.5625 31.8125 27.95 31.8125 30.875C31.8125 33.8 29.425 36.1875 26.5 36.1875ZM26.5 27.4375C24.6 27.4375 23.0625 28.975 23.0625 30.875C23.0625 32.775 24.6 34.3125 26.5 34.3125C28.4 34.3125 29.9375 32.775 29.9375 30.875C29.9375 28.975 28.4 27.4375 26.5 27.4375Z"
        fill="#0E0E0E"
      />
    </svg>
  );
}
export const Notification = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#EDEBEB" />
      <path
        d="M24.0248 34.6629C21.1123 34.6629 18.1998 34.2004 15.4373 33.2754C14.3873 32.9129 13.5873 32.1754 13.2373 31.2129C12.8748 30.2504 12.9998 29.1879 13.5748 28.2379L15.0123 25.8504C15.3123 25.3504 15.5873 24.3504 15.5873 23.7629V20.1504C15.5873 15.5004 19.3748 11.7129 24.0248 11.7129C28.6748 11.7129 32.4623 15.5004 32.4623 20.1504V23.7629C32.4623 24.3379 32.7373 25.3504 33.0373 25.8629L34.4623 28.2379C34.9998 29.1379 35.0998 30.2254 34.7373 31.2129C34.3748 32.2004 33.5873 32.9504 32.5998 33.2754C29.8498 34.2004 26.9373 34.6629 24.0248 34.6629ZM24.0248 13.5879C20.4123 13.5879 17.4623 16.5254 17.4623 20.1504V23.7629C17.4623 24.6754 17.0873 26.0254 16.6248 26.8129L15.1873 29.2004C14.9123 29.6629 14.8373 30.1504 14.9998 30.5629C15.1498 30.9879 15.5248 31.3129 16.0373 31.4879C21.2623 33.2379 26.7998 33.2379 32.0248 31.4879C32.4748 31.3379 32.8248 31.0004 32.9873 30.5504C33.1498 30.1004 33.1123 29.6129 32.8623 29.2004L31.4248 26.8129C30.9498 26.0004 30.5873 24.6629 30.5873 23.7504V20.1504C30.5873 16.5254 27.6498 13.5879 24.0248 13.5879Z"
        fill="#292D32"
      />
      <path
        d="M26.3498 13.9246C26.2623 13.9246 26.1748 13.9121 26.0873 13.8871C25.7248 13.7871 25.3748 13.7121 25.0373 13.6621C23.9748 13.5246 22.9498 13.5996 21.9873 13.8871C21.6373 13.9996 21.2623 13.8871 21.0248 13.6246C20.7873 13.3621 20.7123 12.9871 20.8498 12.6496C21.3623 11.3371 22.6123 10.4746 24.0373 10.4746C25.4623 10.4746 26.7123 11.3246 27.2248 12.6496C27.3498 12.9871 27.2873 13.3621 27.0498 13.6246C26.8623 13.8246 26.5998 13.9246 26.3498 13.9246Z"
        fill="#292D32"
      />
      <path
        d="M24.0254 37.5127C22.7879 37.5127 21.5879 37.0127 20.7129 36.1377C19.8379 35.2627 19.3379 34.0627 19.3379 32.8252H21.2129C21.2129 33.5627 21.5129 34.2877 22.0379 34.8127C22.5629 35.3377 23.2879 35.6377 24.0254 35.6377C25.5754 35.6377 26.8379 34.3752 26.8379 32.8252H28.7129C28.7129 35.4127 26.6129 37.5127 24.0254 37.5127Z"
        fill="#292D32"
      />
    </svg>
  );
}

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_877_827)">
        <path
          d="M11.6164 10.9301L8.76034 7.95969C9.49468 7.08675 9.89702 5.98841 9.89702 4.845C9.89702 2.17351 7.72352 0 5.05203 0C2.38054 0 0.207031 2.17351 0.207031 4.845C0.207031 7.51648 2.38054 9.68999 5.05203 9.68999C6.05494 9.68999 7.01067 9.38749 7.82779 8.81326L10.7055 11.8062C10.8258 11.9311 10.9876 12 11.1609 12C11.325 12 11.4807 11.9374 11.5989 11.8237C11.85 11.5821 11.858 11.1814 11.6164 10.9301ZM5.05203 1.26391C7.02668 1.26391 8.63311 2.87034 8.63311 4.845C8.63311 6.81965 7.02668 8.42608 5.05203 8.42608C3.07738 8.42608 1.47094 6.81965 1.47094 4.845C1.47094 2.87034 3.07738 1.26391 5.05203 1.26391Z"
          fill="#364A5C"
        />
      </g>
      <defs>
        <clipPath id="clip0_877_827">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M20.5402 6.82L14.7802 2.79C13.2102 1.69 10.8002 1.75 9.29023 2.92L4.28023 6.83C3.28023 7.61 2.49023 9.21 2.49023 10.47V17.37C2.49023 19.92 4.56023 22 7.11023 22H17.8902C20.4402 22 22.5102 19.93 22.5102 17.38V10.6C22.5102 9.25 21.6402 7.59 20.5402 6.82Z"
        fill="#0047A7"
      />
      <path
        d="M12.5 18.75C12.09 18.75 11.75 18.41 11.75 18V15C11.75 14.59 12.09 14.25 12.5 14.25C12.91 14.25 13.25 14.59 13.25 15V18C13.25 18.41 12.91 18.75 12.5 18.75Z"
        fill="#0047A7"
      />
    </svg>
  );
};
export const LiveIcon = () => {
  return (
    <svg
      width="70"
      height="54"
      viewBox="0 0 70 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_624_9720)">
        <path
          d="M32.9023 39.2109L30.9258 33.2812C30.6719 32.5156 29.957 32 29.1484 32H23.625V39.5H32.9492C32.9336 39.4023 32.9336 39.3047 32.9023 39.2109ZM22.375 32H16.8516C16.043 32 15.3281 32.5156 15.0742 33.2812L13.0977 39.2109C13.0664 39.3047 13.0664 39.4023 13.0508 39.5H22.375V32ZM13 40.75V50.125C13 51.1602 13.8398 52 14.875 52H31.125C32.1602 52 33 51.1602 33 50.125V40.75H13Z"
          fill="#28C24E"
        />
      </g>
      <g clip-path="url(#clip1_624_9720)">
        <path
          d="M54.9023 12.2109L52.9258 6.28125C52.6719 5.51562 51.957 5 51.1484 5H45.625V12.5H54.9492C54.9336 12.4023 54.9336 12.3047 54.9023 12.2109ZM44.375 5H38.8516C38.043 5 37.3281 5.51562 37.0742 6.28125L35.0977 12.2109C35.0664 12.3047 35.0664 12.4023 35.0508 12.5H44.375V5ZM35 13.75V23.125C35 24.1602 35.8398 25 36.875 25H53.125C54.1602 25 55 24.1602 55 23.125V13.75H35Z"
          fill="#28C24E"
        />
      </g>
      <path
        d="M27.4241 8.9248C27.7331 8.88799 27.9538 8.60763 27.917 8.29859L27.3172 3.26253C27.2804 2.95349 27.0001 2.73281 26.691 2.76961C26.382 2.80641 26.1613 3.08677 26.1981 3.39581L26.7312 7.87231L22.2547 8.40543C21.9457 8.44224 21.725 8.7226 21.7618 9.03163C21.7986 9.34067 22.079 9.56136 22.388 9.52456L27.4241 8.9248ZM10.9756 29.1765C5.35104 24.749 4.38057 16.6002 8.80803 10.9756L7.92245 10.2785C3.10999 16.3922 4.16484 25.2496 10.2785 30.062L10.9756 29.1765ZM8.80803 10.9756C13.2355 5.35103 21.3843 4.38057 27.0089 8.80803L27.706 7.92244C21.5923 3.10999 12.7349 4.16484 7.92245 10.2785L8.80803 10.9756Z"
        fill="#28C24E"
      />
      <path
        d="M41.0089 43.2588C40.6977 43.2539 40.4415 43.5022 40.4366 43.8134L40.3564 48.8844C40.3515 49.1956 40.5998 49.4518 40.911 49.4567C41.2222 49.4616 41.4784 49.2134 41.4833 48.9022L41.5545 44.3946L46.0621 44.4658C46.3733 44.4707 46.6295 44.2225 46.6345 43.9113C46.6394 43.6001 46.3911 43.3439 46.0799 43.3389L41.0089 43.2588ZM60.0214 25.3925C65.0024 30.5334 64.8728 38.7388 59.7319 43.7197L60.5161 44.5291C66.104 39.1151 66.2449 30.1962 60.8309 24.6083L60.0214 25.3925ZM59.7319 43.7197C54.591 48.7007 46.3857 48.571 41.4047 43.4301L40.5953 44.2144C46.0094 49.8023 54.9282 49.9432 60.5161 44.5291L59.7319 43.7197Z"
        fill="#28C24E"
      />
      <defs>
        <clipPath id="clip0_624_9720">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(13 32)"
          />
        </clipPath>
        <clipPath id="clip1_624_9720">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(35 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const PaymentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M17.5 21.25H7.5C3.85 21.25 1.75 19.15 1.75 15.5V8.5C1.75 4.85 3.85 2.75 7.5 2.75H17.5C21.15 2.75 23.25 4.85 23.25 8.5V15.5C23.25 19.15 21.15 21.25 17.5 21.25ZM7.5 4.25C4.64 4.25 3.25 5.64 3.25 8.5V15.5C3.25 18.36 4.64 19.75 7.5 19.75H17.5C20.36 19.75 21.75 18.36 21.75 15.5V8.5C21.75 5.64 20.36 4.25 17.5 4.25H7.5Z"
        fill="#818181"
      />
      <path
        d="M12.5 15.75C10.43 15.75 8.75 14.07 8.75 12C8.75 9.93 10.43 8.25 12.5 8.25C14.57 8.25 16.25 9.93 16.25 12C16.25 14.07 14.57 15.75 12.5 15.75ZM12.5 9.75C11.26 9.75 10.25 10.76 10.25 12C10.25 13.24 11.26 14.25 12.5 14.25C13.74 14.25 14.75 13.24 14.75 12C14.75 10.76 13.74 9.75 12.5 9.75Z"
        fill="#818181"
      />
      <path
        d="M6 15.25C5.59 15.25 5.25 14.91 5.25 14.5V9.5C5.25 9.09 5.59 8.75 6 8.75C6.41 8.75 6.75 9.09 6.75 9.5V14.5C6.75 14.91 6.41 15.25 6 15.25Z"
        fill="#818181"
      />
      <path
        d="M19 15.25C18.59 15.25 18.25 14.91 18.25 14.5V9.5C18.25 9.09 18.59 8.75 19 8.75C19.41 8.75 19.75 9.09 19.75 9.5V14.5C19.75 14.91 19.41 15.25 19 15.25Z"
        fill="#818181"
      />
    </svg>
  );
};

export const InvoicingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M19.92 11.75H16.5C16.09 11.75 15.75 11.41 15.75 11V4.01C15.75 3.27 16.04 2.58 16.56 2.06C17.08 1.54 17.77 1.25 18.51 1.25H18.52C19.77 1.26 20.95 1.75 21.85 2.64C22.75 3.55 23.24 4.75 23.24 6V8.42C23.25 10.41 21.91 11.75 19.92 11.75ZM17.25 10.25H19.92C21.08 10.25 21.75 9.58 21.75 8.42V6C21.75 5.14 21.41 4.32 20.8 3.7C20.19 3.1 19.38 2.76 18.52 2.75C18.52 2.75 18.52 2.75 18.51 2.75C18.18 2.75 17.86 2.88 17.62 3.12C17.38 3.36 17.25 3.67 17.25 4.01V10.25Z"
        fill="#818181"
      />
      <path
        d="M9.49976 23.33C9.02976 23.33 8.58977 23.15 8.25977 22.81L6.59973 21.14C6.50973 21.05 6.36978 21.04 6.26978 21.12L4.55975 22.4C4.02975 22.8 3.32974 22.87 2.72974 22.57C2.12974 22.27 1.75977 21.67 1.75977 21V6C1.75977 2.98 3.48977 1.25 6.50977 1.25H18.5098C18.9198 1.25 19.2598 1.59 19.2598 2C19.2598 2.41 18.9198 2.75 18.5098 2.75C17.8198 2.75 17.2598 3.31 17.2598 4V21C17.2598 21.67 16.8897 22.27 16.2897 22.57C15.6997 22.87 14.9898 22.8 14.4598 22.4L12.7498 21.12C12.6498 21.04 12.5097 21.06 12.4297 21.14L10.7498 22.82C10.4098 23.15 9.96976 23.33 9.49976 23.33ZM6.40973 19.57C6.86973 19.57 7.31973 19.74 7.65973 20.09L9.31976 21.76C9.37976 21.82 9.45976 21.83 9.49976 21.83C9.53976 21.83 9.61975 21.82 9.67975 21.76L11.3597 20.08C11.9797 19.46 12.9598 19.4 13.6498 19.93L15.3497 21.2C15.4597 21.28 15.5597 21.25 15.6097 21.22C15.6597 21.19 15.7498 21.13 15.7498 21V4C15.7498 3.55 15.8597 3.12 16.0497 2.75H6.49976C4.27976 2.75 3.24976 3.78 3.24976 6V21C3.24976 21.14 3.33977 21.2 3.38977 21.23C3.44977 21.26 3.54978 21.28 3.64978 21.2L5.35974 19.92C5.66974 19.69 6.03973 19.57 6.40973 19.57Z"
        fill="#818181"
      />
      <path
        d="M12.5 9.75H6.5C6.09 9.75 5.75 9.41 5.75 9C5.75 8.59 6.09 8.25 6.5 8.25H12.5C12.91 8.25 13.25 8.59 13.25 9C13.25 9.41 12.91 9.75 12.5 9.75Z"
        fill="#818181"
      />
      <path
        d="M11.75 13.75H7.25C6.84 13.75 6.5 13.41 6.5 13C6.5 12.59 6.84 12.25 7.25 12.25H11.75C12.16 12.25 12.5 12.59 12.5 13C12.5 13.41 12.16 13.75 11.75 13.75Z"
        fill="#818181"
      />
    </svg>
  );
};
export const ExpenseIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 9.75H7.5C7.09 9.75 6.75 9.41 6.75 9C6.75 8.59 7.09 8.25 7.5 8.25H13.5C13.91 8.25 14.25 8.59 14.25 9C14.25 9.41 13.91 9.75 13.5 9.75Z" fill="#818181"/>
<path d="M19.5399 14.7999C18.0299 14.7999 16.7499 13.6799 16.6299 12.2399C16.5499 11.4099 16.8499 10.5999 17.4499 10.0099C17.9499 9.48995 18.6599 9.19995 19.4099 9.19995H21.4999C22.4899 9.22995 23.2499 10.0099 23.2499 10.9699V13.03C23.2499 13.99 22.4899 14.7699 21.5299 14.7999H19.5399ZM21.4699 10.7H19.4199C19.0699 10.7 18.7499 10.8299 18.5199 11.0699C18.2299 11.3499 18.0899 11.7299 18.1299 12.1099C18.1799 12.7699 18.8199 13.2999 19.5399 13.2999H21.4999C21.6299 13.2999 21.7499 13.18 21.7499 13.03V10.9699C21.7499 10.8199 21.6299 10.71 21.4699 10.7Z" fill="#818181"/>
<path d="M16.5 21.25H7.5C4.06 21.25 1.75 18.94 1.75 15.5V8.5C1.75 5.42 3.64998 3.19001 6.59998 2.82001C6.86998 2.78001 7.18 2.75 7.5 2.75H16.5C16.74 2.75 17.05 2.76 17.37 2.81C20.32 3.15 22.25 5.39 22.25 8.5V9.95001C22.25 10.36 21.91 10.7 21.5 10.7H19.42C19.07 10.7 18.75 10.83 18.52 11.07L18.51 11.08C18.23 11.35 18.1 11.72 18.13 12.1C18.18 12.76 18.82 13.29 19.54 13.29H21.5C21.91 13.29 22.25 13.63 22.25 14.04V15.49C22.25 18.94 19.94 21.25 16.5 21.25ZM7.5 4.25C7.26 4.25 7.02999 4.26999 6.79999 4.29999C4.59999 4.57999 3.25 6.18 3.25 8.5V15.5C3.25 18.08 4.92 19.75 7.5 19.75H16.5C19.08 19.75 20.75 18.08 20.75 15.5V14.8H19.54C18.03 14.8 16.75 13.68 16.63 12.24C16.55 11.42 16.85 10.6 17.45 10.02C17.97 9.49002 18.67 9.20001 19.42 9.20001H20.75V8.5C20.75 6.16 19.38 4.54998 17.16 4.28998C16.92 4.24998 16.71 4.25 16.5 4.25H7.5Z" fill="#818181"/>
</svg>
  );
};
export const AnalyticsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M22.5 22.75H2.5C2.09 22.75 1.75 22.41 1.75 22C1.75 21.59 2.09 21.25 2.5 21.25H22.5C22.91 21.25 23.25 21.59 23.25 22C23.25 22.41 22.91 22.75 22.5 22.75Z"
        fill="#818181"
      />
      <path
        d="M14.75 22.75H10.25C9.84 22.75 9.5 22.41 9.5 22V4C9.5 2.28 10.45 1.25 12.05 1.25H12.95C14.55 1.25 15.5 2.28 15.5 4V22C15.5 22.41 15.16 22.75 14.75 22.75ZM11 21.25H14V4C14 2.85 13.46 2.75 12.95 2.75H12.05C11.54 2.75 11 2.85 11 4V21.25Z"
        fill="#818181"
      />
      <path
        d="M7.5 22.75H3.5C3.09 22.75 2.75 22.41 2.75 22V10C2.75 8.28 3.63 7.25 5.1 7.25H5.9C7.37 7.25 8.25 8.28 8.25 10V22C8.25 22.41 7.91 22.75 7.5 22.75ZM4.25 21.25H6.75V10C6.75 8.75 6.2 8.75 5.9 8.75H5.1C4.8 8.75 4.25 8.75 4.25 10V21.25Z"
        fill="#818181"
      />
      <path
        d="M21.5 22.75H17.5C17.09 22.75 16.75 22.41 16.75 22V15C16.75 13.28 17.63 12.25 19.1 12.25H19.9C21.37 12.25 22.25 13.28 22.25 15V22C22.25 22.41 21.91 22.75 21.5 22.75ZM18.25 21.25H20.75V15C20.75 13.75 20.2 13.75 19.9 13.75H19.1C18.8 13.75 18.25 13.75 18.25 15V21.25Z"
        fill="#818181"
      />
    </svg>
  );
};
export const AccountIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M19.5 13.36H2.5C2.09 13.36 1.75 13.02 1.75 12.61C1.75 12.2 2.09 11.86 2.5 11.86H19.5C19.91 11.86 20.25 12.2 20.25 12.61C20.25 13.02 19.91 13.36 19.5 13.36Z"
        fill="#818181"
      />
      <path
        d="M15.72 21.75H6.28003C2.85003 21.75 1.75 20.66 1.75 17.27V10.28C1.75 7.58999 2.36002 5.99999 5.46002 5.81999C5.72002 5.80999 5.99003 5.79999 6.28003 5.79999H15.72C19.15 5.79999 20.25 6.88999 20.25 10.28V17.43C20.21 20.7 19.11 21.75 15.72 21.75ZM6.28003 7.29999C6.01003 7.29999 5.76003 7.30999 5.53003 7.31999C3.74003 7.42999 3.25 7.80999 3.25 10.28V17.27C3.25 19.83 3.67003 20.25 6.28003 20.25H15.72C18.3 20.25 18.72 19.85 18.75 17.42V10.28C18.75 7.71999 18.33 7.29999 15.72 7.29999H6.28003Z"
        fill="#818181"
      />
      <path
        d="M19.5 18.18C19.31 18.18 19.12 18.11 18.99 17.98C18.84 17.84 18.75 17.64 18.75 17.43V10.28C18.75 7.72 18.33 7.3 15.72 7.3H6.28003C6.01003 7.3 5.76003 7.31 5.53003 7.32C5.33003 7.33 5.12998 7.25 4.97998 7.11C4.82998 6.97 4.75 6.77 4.75 6.56C4.79 3.3 5.89003 2.25 9.28003 2.25H18.72C22.15 2.25 23.25 3.34 23.25 6.73V13.72C23.25 16.41 22.64 18 19.54 18.18C19.53 18.18 19.51 18.18 19.5 18.18ZM6.28003 5.8H15.72C19.15 5.8 20.25 6.89 20.25 10.28V16.6C21.41 16.39 21.75 15.79 21.75 13.72V6.73C21.75 4.17 21.33 3.75 18.72 3.75H9.28003C7.00003 3.75 6.41003 4.06 6.28003 5.8Z"
        fill="#818181"
      />
      <path
        d="M7.46027 18.56H5.74023C5.33023 18.56 4.99023 18.22 4.99023 17.81C4.99023 17.4 5.33023 17.06 5.74023 17.06H7.46027C7.87027 17.06 8.21027 17.4 8.21027 17.81C8.21027 18.22 7.88027 18.56 7.46027 18.56Z"
        fill="#818181"
      />
      <path
        d="M13.0494 18.56H9.60938C9.19938 18.56 8.85938 18.22 8.85938 17.81C8.85938 17.4 9.19938 17.06 9.60938 17.06H13.0494C13.4594 17.06 13.7994 17.4 13.7994 17.81C13.7994 18.22 13.4694 18.56 13.0494 18.56Z"
        fill="#818181"
      />
    </svg>
  );
};
