import React, { useState } from "react";
import arrow from "../../assets/svgs/arrow-left.svg";
import sampA from "../../assets/png/upload-pic.png";

import { Btn } from "../Button";

const UploadProgress = ({
  setPresent,
}: {
  setPresent: React.Dispatch<React.SetStateAction<number>>;
}) => {
 
  return (
    <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border bg-white border rounded-[8px] px-8 pt-8 pb-6 flex flex-col ">
      <div
        className="flex space-x-3 items-center cursor-pointer"
        onClick={() => setPresent(3)}
      >
        <img src={arrow} alt="arrow" />
        <p className="text-[#9D9D9D] text-sm">Back</p>
      </div>
      <div className="flex flex-col mt-[35px]">
        <h1 className="text-black text-[31px] font-bold mb-8 ">
          Upload your file(s)
        </h1>

        <p className="text-sm text-black">Select file(s) to upload</p>
        <div className="flex flex-col  flow-hide gap-y-3 mt-8">
          <div
            className={`w-full min-h-[72px] h-[72px] flex items-center px-6 cursor-pointer justify-between border-border border border-dashed rounded-[4px] hover:bg-grey-10 relative  `}
          >
            {/* left */}
            <div className="flex items-center gap-4 w-full">
              <span>
                <img
                  src={sampA}
                  alt="samp"
                  className="w-6 h-auto rounded-[4px] "
                />
              </span>
                          <div className="w-full flex-grow flex h-[8px] bg-[#E4E4E7] rounded-[4px] ">
                              <div className="flex w-[40%] h-[8px] rounded-[4px] bg-black"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 mb-6 w-full flex justify-end items-center ">
          <Btn name="Continue" active={true} onClick={() => setPresent(5)} />
        </div>
      </div>
    </div>
  );
};

export default UploadProgress;
