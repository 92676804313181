import React, { ChangeEvent ,useState} from 'react'
import { text } from 'stream/consumers'
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

interface inputProps {
  name?: string;
  placeholder?: string;
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  src?: any;
  type?: any;
}


export const NormalInput = ({ name, placeholder, className, onChange, value, src, type }: inputProps) => {
  return (
    <div className={`w-full flex flex-col border border-border px-4 py-[6px] ${className}`}>
      <label className='text-[#041D33] text-sm font-[200] '>{name}</label>
      <div className="flex justify-between mt-[5px] h-5">
        <input type={type ? type : "text"} onChange={onChange} className='w-10/12 h-5 focus:bg-transparent bg-transparent outline-none border-none placeholder:text-secondary text-black' placeholder={placeholder}  />
        <span>
        <img src={src} alt="icon" className='w-[12px] h-[12px]' />
        </span>
      </div>
   </div>
  );
};


export const PasswordInput = ({ name, placeholder, className, onChange, value }: inputProps) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  return (
    <div
      className={`flex ${className} items-center border bg-white   rounded-[10px] py-[18px] px-[16px] h-[52px] w-full  ${
        value ? "border-[#041C40] border-[2px]" : "border-[#cfcfcf]"
      }
`}
      onMouseEnter={() => setActive(!active)}
    >
      <div className="flex flex-col w-11/12 justify-center">
        <label
          htmlFor="name"
          className="block text-xs font-medium text-[#363636]"
        >
          {value ? name : ""}
        </label>
        <input
          className={` w-full pr-2  placeholder:text-placeholder  outline-none bg-inherit  `}
          type={show ? "text" : "password"}
          name="nam"
          onChange={onChange}
          autoComplete="off"
          placeholder={placeholder}
        />
      </div>
      {show ? (
        <AiFillEye
          className="text-customBlack text-3xl cursor-pointer"
          onClick={() => setShow(false)}
        />
      ) : (
        <AiFillEyeInvisible
          className="text-customBlack text-3xl cursor-pointer"
          onClick={() => setShow(true)}
        />
      )}
    </div>
  );
}