import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/svgs/v-logo.svg";
import cancel from "../../assets/svgs/close-circle.svg";
import ArrowLeft from "../../assets/svgs/arrow-left2.svg";
import layer from "../../assets/svgs/Layer 2.svg";
import copy from "../../assets/svgs/copy.svg";
import card from "../../assets/svgs/card.svg"

function Payment() {
    const navigate = useNavigate();

    const HandleCancel = () => {
        navigate("")
    }
  return (
    <div className='w-11/12 h-auto m-auto'>
        <div onClick={HandleCancel} className="w-full flex justify-between items-center mt-5">
            <img src={logo} alt="logo"  />
            <img
                src={cancel}
                alt="cancel icon"
                className="hover:bg-slate-300 rounded-full p-3 cursor-pointer"
            />
        </div>
        <div className="w-2/5 bg-sate-800 flex justify-start items-center mt-8">
            <img
            src={ArrowLeft}
            alt="arrow left icon"
            className="hover:bg-slate-300 rounded-full p-1 cursor-pointer"
            /> 
            <p className='ml-2 text-[16px] not-italic font-semibold'>Back</p>
        </div>

        <div className="conta not-italic">
            <div className="center">
                <p className='text-[32px] font-semibold'>Invoice # 001</p>
                <hr className='w-[82%] my-4 bg-[#fff]' />
                <p className='text-[24px] font-normal'>Date Issued: <span className='font-semibold'>02-02-2023</span></p>
                <p className='text-[24px] font-normal'>Due Date: <span className='font-semibold'>12-12-2023</span></p>
            </div>
            
            <div className="flex justify-end w-[94%]">
                <img src={layer} alt="" className='w-2/12' />
            </div>
        </div>

        <div className="w-11/12 m-auto flex justify-between my-8">
            <div className="not-italic">
                <p className='text-[20px] font-semibold text-[#1D3447]'>From</p>
                <p className='text-[18px] font-medium text-[#828E99]'>Ibrahim Musa Damisa</p>
            </div>

            <div className="not-italic">
                <p className='text-[20px] font-semibold text-[#1D3447]'>To</p>
                <p className='text-[18px] font-medium text-[#828E99]'>Kwatmi Haruna Paul</p>
            </div>
        </div>

        <table className='w-full'>
            <tr className='text-left h-8 border-b'>
                <th className='text-[#4F6170] text-[14px] font-normal w-2/4'>Name</th>
                <th className='text-[#4F6170] text-[14px] font-normal'>Amount ($)</th>
                <th className='text-[#4F6170] text-[14px] font-normal'>Quantity</th>
                <th className='text-[#4F6170] text-[14px] font-normal'>Discount ($)</th>
                <th className='text-[#4F6170] text-[14px] font-normal'>Tax</th>
                <th className='text-[#000] text-[16px] font-semibold'>Total ($)</th>
            </tr>

            

            <tr className='text-[14px] font-normal text-[#4F6170] h-12 border-b'>
                <td className=''>
                    <span className='text-[16px] font-bold'>Item</span>
                    <br />
                    Description
                </td>
                <td>0.00</td>
                <td>1</td>
                <td>0.00</td>
                <td>0% (0.00)</td>
                <td className='text-[#000] text-[16px] font-semibold'>0.00</td>
            </tr>

            <tr className='border-b h-12'>
                <td className='text-[14px] font-normal text-[#4F6170]'>
                    <span className='text-[16px] font-bold'>Item</span>
                    <br />
                    Description
                </td>
                <td>0.00</td>
                <td>1</td>
                <td>0.00</td>
                <td>0% (0.00)</td>
                <td className='text-[#000] text-[16px] font-semibold'>0.00</td>
            </tr>

            <tr className='border-b h-12'>
                <td className='text-[14px] font-normal text-[#4F6170]'>
                    <span className='text-[16px] font-bold'>Item</span>
                    <br />
                    Description
                </td>
                <td>0.00</td>
                <td>1</td>
                <td>0.00</td>
                <td>0% (0.00)</td>
                <td className='text-[#000] text-[16px] font-semibold'>0.00</td>
            </tr>

            <tr className='text-[14px] font-normal text-[#4F6170] h-12'>
                <td></td>
                <td></td>
                <td></td>
                <td>Subtotal</td>
                <td></td>
                <td className='text-[16px] font-semibold'>0.00</td>
            </tr>

            <tr className='text-[14px] font-normal text-[#4F6170] h-12'>
                <td></td>
                <td></td>
                <td></td>
                <td>Tax</td>
                <td></td>
                <td className='text-[16px] font-semibold'>0.00</td>
            </tr>

            <tr className='text-[14px] font-normal text-[#4F6170] h-12'>
                <td></td>
                <td></td>
                <td></td>
                <td>Discount</td>
                <td></td>
                <td className='text-[16px] font-semibold'>0% ($0.00)</td>
            </tr>

            <tr className='text-[#000] text-[16px] font-semibold h-12'>
                <td></td>
                <td></td>
                <td></td>
                <td>Amount Due</td>
                <td></td>
                <td className=''>0.00</td>
            </tr>
        </table>
        <div className="w-full flex justify-end my-10">
          <div className="bg-[#E8E8FF] flex items-center justify-center self-center py-3 px-2 rounded-md text-[#2F80EC]">
            <p className="text-[14px] not-italic font-medium leading-normal text-right mr-2">Copy account details</p>
            <img src={copy} alt="copy icon" />
          </div>

          <div className="bg-[#2F80EC] flex items-center justify-center self-center py-3 px-2 rounded-md text-[#fff] ml-5">
            <p className="text-[14px] not-italic font-medium leading-normal text-right mr-2">Pay now</p>
            <img src={card} alt="card icon" />
          </div>
        </div>
    </div>
  )
}

export default Payment