import React from "react";
import { useNavigate } from "react-router-dom"
import us from "../../assets/png/us.png";
import ngn from "../../assets/png/ngn.png";
import british from "../../assets/png/british.png";
import europe from "../../assets/png/europe.png";
import {
  RequestIcon,
  CreateIcon,
  ScheduleIcon,
  DrillIcon,
} from "../../assets/svgs/QuickActions";
import arrowUp from "../../assets/svgs/arrow-up.svg";
import arrowDown from "../../assets/svgs/arrow-down.svg";


const Content = () => {
  const navigate = useNavigate();
  const HandleRoutes = () => {
    navigate("/home/naira")
  }

  return (
    <div className="w-full px-10 2xl:px-[72px] flex flex-col bg-white h-screen flow-hide">
      <h1 className="text-secondary text-[31px] font-bold mt-[70px]">
        Hello, Kwatmi
      </h1>
      <div className="grid grid-cols-4 mt-6 gap-x-4">
        {/* single item */}
        <div onClick={HandleRoutes} className="w-full flex flex-col p-6 bg-[#EAF2FD] cursor-pointer">
          <div className="flex items-center space-x-2">
            <img src={ngn} alt="sdsds" />
            <p className="text-black text-sm font-bold capitalize">
              Nigerian Naira
            </p>
          </div>
          <div className="mt-[60px] flex flex-col">
            <h1 className="text-[#0e0e0e] text-[31px] font-bold">0.00</h1>
            <p className="text-secondary text-base font-[300]">
              Naira Total balance
            </p>
          </div>
        </div>
        {/* end of single #$$$$$$$$$$$$$$$ item */}
        {/* single item */}
        <div className="w-full flex flex-col p-6 bg-[#EAF2FD] ">
          <div className="flex items-center space-x-2">
            <img src={us} alt="ebrhe" />
            <p className="text-black text-sm font-bold capitalize">
              United States Dollar
            </p>
          </div>
          <div className="mt-[60px] flex flex-col">
            <h1 className="text-[#0e0e0e] text-[31px] font-bold">0.00</h1>
            <p className="text-secondary text-base font-[300]">
              USD Total balance
            </p>
          </div>
        </div>
        {/* end of single #$$$$$$$$$$$$$$$ item */}
        {/* single item */}
        <div className="w-full flex flex-col p-6 bg-[#F1F1F1]">
          <div className="flex items-center space-x-2">
            <img src={europe} alt="sdsds" />
            <p className="text-black text-sm font-bold capitalize">
              Euros
            </p>
          </div>
          <div className="mt-[64px] flex flex-col">
            <h1 className="text-[#0e0e0e] text-[31px] font-bold opacity-0">
              0.00
            </h1>
            <p className="text-primary text-sm font-bold">Activate</p>
          </div>
        </div>
        {/* end of single #$$$$$$$$$$$$$$$ item */}
        {/* single item */}
        <div className="w-full flex flex-col p-6 bg-[#F1F1F1]">
          <div className="flex items-center space-x-2">
            <img src={british} alt="sdsds" />
            <p className="text-black text-sm font-bold capitalize">
              Britsh Pounds Sterling
            </p>
          </div>
          <div className="mt-[64px] flex flex-col">
            <h1 className="text-[#0e0e0e] text-[31px] font-bold opacity-0">
              0.00
            </h1>
            <p className="text-primary text-sm font-bold">Activate</p>
          </div>
        </div>
        {/* end of single #$$$$$$$$$$$$$$$ item */}
      </div>
      <div className="w-full mt-[72px] ">
        <h1 className="text-secondary font-bold text-[25px]">Quick Actions</h1>
        <div className="w-full mt-6 grid grid-cols-4 gap-x-4">
          {/* start of a sec */}
          <div className="w-full flex flex-col p-6 rounded-[12px] bg-dark text-white">
            <p className="text-base font-bold 2xl:w-7/12 lg:w-11/12">
              Request payment from a client
            </p>
            <div className="mt-[36px]">
              <RequestIcon />
            </div>
          </div>
          {/* END of a sec */}
          {/* start of a sec */}
          <div className="w-full flex flex-col p-6 rounded-[12px] bg-dark text-white">
            <p className="text-base font-bold 2xl:w-7/12 lg:w-11/12">
              Create and send an Invoice
            </p>
            <div className="mt-[36px]">
              <CreateIcon />
            </div>
          </div>
          {/* END of a sec */}
          {/* start of a sec */}
          <div className="w-full flex flex-col p-6 rounded-[12px] bg-dark text-white">
            <p className="text-base font-bold 2xl:w-7/12 lg:w-11/12">
              Schedule transfer and payments
            </p>
            <div className="mt-[36px]">
              <ScheduleIcon />
            </div>
          </div>
          {/* END of a sec */}
          {/* start of a sec */}
          <div className="w-full flex flex-col p-6 rounded-[12px] bg-dark text-white">
            <p className="text-base font-bold 2xl:w-7/12 lg:w-11/12">
              Drill mode
            </p>
            <div className="mt-[56px]">
              <DrillIcon />
            </div>
          </div>
          {/* END of a sec */}
        </div>
      </div>
      <div className="w-full mt-[72px] mb-20 ">
        <h1 className="text-secondary font-bold text-[25px]">Transactions</h1>
        <div className="w-full mt-6 flex flex-col space-y-2">
          {/* one side */}
          <div className="flex bg-[#F2F4F5] w-full justify-between items-center p-3">
            {/* left */}
            <div className="flex items-center space-x-4">
              <img
                src={arrowUp}
                alt="arrow"
            
              />
              <div className="flex flex-col">
                <h1 className="text-black not-italic text-[25px] font-semibold leading-normal">
                  Settlement
                </h1>
                <p className="text-[18px] not-italic font-medium leading-normal text-[#717E95] ">Sent 12 Mar 2023</p>
              </div>
            </div>
            {/* right */}
            <h1 className="text-[#0e0e0e] text-[25px] font-semibold">32,123</h1>
          </div>
          {/* end of it */}
          {/* one side */}
          <div className="flex bg-[#fff] w-full justify-between items-center p-3">
            {/* left */}
            <div className="flex items-center space-x-4">
              <img
                src={arrowUp}
                alt="arrow"
               
              />
              <div className="flex flex-col">
              <h1 className="text-black not-italic text-[25px] font-semibold leading-normal">
                  Settlement
                </h1>
                <p className="text-[18px] not-italic font-medium leading-normal text-[#717E95] ">Sent 12 Mar 2023</p>
              </div>
            </div>
            {/* right */}
            <h1 className="text-[#0e0e0e] text-[25px] font-semibold">32,123</h1>
          </div>
          {/* end of it */}
          {/* one side */}
          <div className="flex bg-[#fff] w-full justify-between items-center p-3">
            {/* left */}
            <div className="flex items-center space-x-4">
              <img
                src={arrowDown}
                alt="arrow"
               
              />
              <div className="flex flex-col">
              <h1 className="text-black not-italic text-[25px] font-semibold leading-normal">
                  Settlement
                </h1>
                <p className="text-[18px] not-italic font-medium leading-normal text-[#717E95] ">Sent 12 Mar 2023</p>
              </div>
            </div>
            {/* right */}
            <h1 className="text-[#0e0e0e] text-[25px] font-semibold">32,123</h1>
          </div>
          {/* end of it */}
        </div>
      </div>
    </div>
  );
};

export default Content;
