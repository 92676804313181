import React from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
// import Content from '../../components/Dashboard/EmptyInvoices';
import Content from "../../components/Dashboard/InvoicesList"


const Home = () => {
  return (
    <DashboardLayout>
      <Content />
    </DashboardLayout>
  );
}

export default Home
