
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "Page A",
    expenses: 4000,
    income: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    expenses: 3000,
    income: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    expenses: 2000,
    income: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    expenses: 2780,
    income: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    expenses: 1890,
    income: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    expenses: 2390,
    income: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    expenses: 3490,
    income: 4300,
    amt: 2100,
  },
];

export default function ExpensesChart() {
    return (
      <div className="w-full bg-[#CFDBE3]">
        <LineChart
          width={400}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* <XAxis dataKey="name" /> */}
          {/* <YAxis /> */}
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="income"
            stroke="#D5FD89"
            strokeWidth={4}
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="expenses"
            stroke="#050505"
            strokeWidth={4}
          />
        </LineChart>
      </div>
    );
}
