import React from 'react'
import arrow from "../../assets/svgs/black-arrow-right.svg"
interface buttonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    active: boolean;
  name: string;
  className?: string;

}
export  const Btn = ({active,onClick,name, className} : buttonProps) => {
  return (
    <button
      className={`${className} ${
        active ? "bg-background" : "bg-background opacity-40"
      } text-customBlack w-fit h-[41px] flex justify-center gap-2 px-5 py-3 rounded-[6px] items-center font-medium  cursor-pointer hover:opacity-70 text-sm `}
      disabled={!active}
      onClick={onClick}
    >
      {name} <span><img src={arrow} alt='arrow icon' /></span>
    </button>
  );
}
export  const OutlineBtn = ({active,onClick,name, className} : buttonProps) => {
  return (
    <button
      className={`${className} border  w-full h-[52px] flex justify-center items-center font-Circular rounded-[10px] cursor-pointer hover:bg-primaryBlue hover:text-white border-primaryBlue  text-primaryBlue`}
      disabled={!active}
      onClick={onClick}
    >
      {name}
    </button>
  );
}

