import React from 'react'
import logo from "../../assets/svgs/v-logo.svg";
import ArrowLeft from "../../assets/svgs/arrow-left2.svg";
import checked from "../../assets/svgs/full-checkbox.svg";
import uncheck from "../../assets/svgs/Checkbox.svg";

function RecipientSidebar() {
  return (
    <div className='w-[20%] h-screen pt-10 relative'>
      <div className="w-full flex justify-center">
        <img alt="logo" src={logo}  />
      </div>

      <div className="w-2/5 bg-sate-800 flex justify-end absolute right-20 items-center mt-8">
        <img
          src={ArrowLeft}
          alt="arrow left icon"
          className="hover:bg-slate-300 rounded-full p-1 cursor-pointer"
        /> 
        <p className='ml-2 text-[16px] not-italic font-semibold'>Back</p>
      </div>
      <div className="w-9/12 h-auto mt-32">  
        <div className="w-full flex justify-end">
          <p className='text-[16px] not-italic font-semibold'>Recipient</p>
          <img src={checked} alt="checked icon" className='ml-5' />
        </div>

        {/* <div className="w-[2px] opacity-50 h-[260px] bg-[#091E42] relative"></div>
        <div className="flex justify-end mr-3">.</div> */}

        <div className="w-full flex justify-end my-32">
          <p className='text-[16px] not-italic font-semibold'>Amount</p>
          <img src={uncheck} alt="uncheck icon" className='ml-5' />
        </div>

        {/* <div className="w-[2px] opacity-50 h-[260px] bg-[#091E42] relative"></div>
        <div className="flex justify-end mr-3">.</div> */}

        <div className="w-full flex justify-end">
          <p className='text-[16px] not-italic font-semibold'>Review</p>
          <img src={uncheck} alt="uncheck icon" className='ml-5' />
        </div>
      </div>
    </div>
  )
}

export default RecipientSidebar