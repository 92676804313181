import { useState, useEffect } from "react";

import { Btn } from "../../components/Button";
import OtpInput from "react-otp-input";
import arrow from "../../assets/svgs/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { MainLogo } from "../../assets/svgs";
import { resetAll, resetSavedPlaid, verifyOtp } from "../../features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const otpVerification = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);

  const handleSend = () => {
    const data = { otp: otp };
    dispatch(verifyOtp(data));
  }

  useEffect(() => {
    if (auth?.verifyOtpSuccess) {
      toast.success("verification successful");
      dispatch(resetAll());
      dispatch(resetSavedPlaid());
      navigate("/connect-account");
    }
  }, [auth?.verifyOtpSuccess])

  

  return (
    <div className="w-full h-screen flex items-center justify-center relative bg-background  flex-col">
      <div className="  flex justify-center items-center">
        <MainLogo />
      </div>

      <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border border rounded-[8px] px-8 pt-8 pb-12 flex flex-col bg-white ">
        <div className="flex space-x-3 items-center cursor-pointer">
          <img src={arrow} alt="arrow" />
          <p className="text-background text-sm font-medium">Back</p>
        </div>
        <div className="flex flex-col mt-[35px]">
          <h1 className="text-black text-[31px] font-bold ">Verify Otp</h1>
          <p className="text-black text-base font-normal mt-2 mb-8">
            Enter the otp sent to your email.
          </p>
          <div className="flex ">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              containerStyle="w-8/12 mx-auto flex justify-between "
              renderInput={(props) => <input {...props} />}
              inputStyle="w-full min-w-[49px] min-h-[44px] rounded-[10px] border border-[#cfcfcf] "
            />
          </div>

          <div className="mt-8 w-full flex justify-end items-center ">
            <Btn
              name={auth?.loading ? "loading" : "verify"}

              active={true}
              onClick={handleSend}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default otpVerification;
