import React from 'react'
import { useNavigate } from 'react-router-dom';
import InvoiceSidebar from '../../components/Sidebar/InvoiceSidebar'
import cancel from "../../assets/svgs/close-circle.svg";
import ArrowRight from "../../assets/svgs/arrow-right.svg"

function NewInvoice() {
    const navigate = useNavigate();

    const HandleCancel = () => {
        navigate("/invoice")
    }

  return (
    <div className="w-full h-screen flex justify-between">
      <InvoiceSidebar />
      <div className="w-[80%] pt-10">
        <div
          onClick={HandleCancel}
          className="w-11/12 m-auto flex justify-end items-center"
        >
          <img
            src={cancel}
            alt="cancel icon"
            className="hover:bg-slate-300 rounded-full p-3 cursor-pointer"
          />
        </div>

        <div className="w-3/5 m-auto mt-16">
          <div className="flex flex-col items-start gap-2 self-stretch text-[#000000] mt-8">
            <h3 className="text-[31.25px] font-bold not-italic leading-normal">
              New Invoice
            </h3>
            <p className="text-[16px] not-italic font-normal leading-normal">
              Who are you sending the money to?
            </p>
          </div>

          {/* center */}
          <div className="w-full border border-border rounded-[6px] py-6 mt-8 px-4 justify-between flex flex-col items-start cursor-pointer hover:bg-[#EAF2FD]">
            <label
              htmlFor=""
              className="text-[#041D33] text-[14px] not-italic font-extralight leading-normal"
            >
              Recipient
            </label>
            <div className="w-full">
              <input
                type="text"
                placeholder="Email, phone, username"
                className="text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none"
              />
            </div>
          </div>
          {/* END of  center */}

          <div
            className="flex justify-between items-center bg-[#2F80EC] rounded-md w-[90px] h-10 px-2 text-center mt-5 cursor-pointer relative left-[80%]"
            onClick={() => navigate("/invoice/invoicedetails")}
          >
            <p className="text-[#fff]">Next</p>
            <img src={ArrowRight} alt="arrow right icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewInvoice