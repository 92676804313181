/* This example requires Tailwind CSS v2.0+ */
import { useEffect } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { VersusPayIcon, BankLogo, UploadIcon } from "../../assets/svgs";
import { NavSettings } from "../../assets/svgs";
import { usePlaidLink, PlaidLinkOnSuccess } from "react-plaid-link";
import ReUseModal from "../Modal/ReUseModal";
import SimplePlaidLink from "../plaid/PlaidComponent";
import { useAppDispatch } from "../../app/hooks";
import { getToken } from "../../features/auth/AuthSlice";


function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function NavDrop({ setOpen }: any) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getToken());
  }, []);
  


  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <NavSettings />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-high ">
            <div className="py-1">
             
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setOpen(true)}
                  >
                    <span className="mr-2">
                      <UploadIcon />
                    </span>
                    Upload File
                  </p>
                )}
              </Menu.Item>
          
              <Menu.Item>
               <SimplePlaidLink />
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}


