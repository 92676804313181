import React, { useState } from "react";
import arrow from "../../assets/svgs/arrow-left.svg";
import sampA from "../../assets/png/samp-logo-one.png";
import sampB from "../../assets/png/upload-pic.png";
import {  CircleCheck } from "../../assets/svgs";
import { Btn } from "../Button";

const data = [
  {
    name: "Source from banks",
    bank: "Gather your information data from your selected bank",
    img: sampA,
  },
  {
    name: "Upload files",
    bank: "Upload financial data from a file (e.g PDF or excel files)",
    img: sampB,
  },
];
const SelectDataSource = ({
  setPresent,
}: {
  setPresent: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [selected, setSelected] = useState<number>(0);
  return (
    <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border bg-white border rounded-[8px] px-8 pt-8 pb-6 flex flex-col ">
      <div
        className="flex space-x-3 items-center cursor-pointer"
        onClick={() => setPresent(1)}
      >
        <img src={arrow} alt="arrow" />
        <p className="text-[#9D9D9D] text-sm">Back</p>
      </div>
      <div className="flex flex-col mt-[35px]">
        <h1 className="text-black text-[31px] font-bold mb-8 ">
          How do you want to bring your data?
        </h1>

        <p className="text-sm text-black">
          Select an option on how you want to bring your data to reelin?
        </p>
        <div className="flex flex-col h-[200px] flow-hide gap-y-3 mt-8">
          {data?.map((item: any, index: number) => {
            return (
              <div
                className={`w-full min-h-[94px] h-[94px] flex items-center px-6 cursor-pointer justify-between gap-4 border-border border rounded-[4px] hover:bg-grey-10 ${
                  selected === index + 1 ? "bg-grey-10" : "bg-white"
                } `}
                key={index}
                onClick={() => setSelected(index + 1)}
              >
                {/* left */}
                <div className="flex items-center gap-4">
                  <span>
                    <img
                      src={item?.img}
                      alt="samp"
                      className="w-10 h-auto rounded-[4px] "
                    />
                  </span>
                  <div className="flex flex-col">
                    <h4 className="text-coloured font-medium text-sm">
                      {item?.name}
                    </h4>
                    <p className="text-sm text-muted">{item?.bank}</p>
                  </div>
                </div>
                {/* right */}
                <span>
                  <CircleCheck />
                </span>
              </div>
            );
          })}
        </div>
        <div className="mt-8 mb-6 w-full flex justify-end items-center ">
          <Btn name="Continue" active={true} onClick={() => setPresent(3)} />
        </div>
      </div>
    </div>
  );
};

export default SelectDataSource;
