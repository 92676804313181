import { useState } from "react";

import { Btn } from "../../components/Button";

import { NormalInput } from "../../components/Input";
import arrow from "../../assets/svgs/arrow-left.svg";
import mail from "../../assets/svgs/sms.svg";
import eye from "../../assets/svgs/eye-slash.svg";
import { MainLogo } from "../../assets/svgs";

const VerifyEmail = () => {
  const [email, setEmail] = useState("");

  return (
    <div className="w-full h-screen flex items-center justify-center relative bg-background  flex-col">
      <div className="  flex justify-center items-center">
        <MainLogo />
          </div>
          
      <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border border rounded-[8px] px-8 pt-8 pb-12 flex flex-col bg-white ">
        <div className="flex space-x-3 items-center cursor-pointer">
          <img src={arrow} alt="arrow" />
          <p className="text-background text-sm font-medium">Back</p>
        </div>
        <div className="flex flex-col mt-[35px]">
          <h1 className="text-black text-[31px] font-bold ">Verify Email</h1>
          <p className="text-black text-base font-normal mt-2 mb-8">
            Enter your email for verification.
          </p>
          <NormalInput
            onChange={(e: any) => setEmail(e.target.value)}
            type={"email"}
            name="Email"
            placeholder="example@mail.com"
            src={mail}
          />

          <div className="mt-8 w-full flex justify-end items-center ">
            <Btn
              name="Verify Email"
              active={true}
              onClick={() => console.log("hehrer")}
            />
          </div>
     
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
