export const RequestIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M53.9669 6.66667H26.0669C13.9336 6.66667 6.7002 13.9 6.7002 26.0333V53.9333C6.7002 66.0666 13.9336 73.3 26.0669 73.3H53.9669C66.1002 73.3 73.3335 66.0666 73.3335 53.9333V26.0333C73.3335 13.9 66.1002 6.66667 53.9669 6.66667Z"
        fill="white"
      />
      <path
        d="M53.3337 30.8H60.0003C61.8337 30.8 63.3337 29.3 63.3337 27.4666V26.6667C63.3337 21.1333 58.867 16.6667 53.3337 16.6667H26.667C21.1337 16.6667 16.667 21.1333 16.667 26.6667V28.3333C16.667 30.1667 18.167 31.6667 20.0003 31.6667H24.467C28.8336 31.6667 32.8003 34.8 33.2337 39.1667C33.7337 44.1667 29.8336 48.3667 24.9336 48.3667H20.0003C18.167 48.3667 16.667 49.8667 16.667 51.7V53.3667C16.667 58.9 21.1337 63.3667 26.667 63.3667H53.3337C58.867 63.3667 63.3337 58.9 63.3337 53.3667V52.5334C63.3337 50.7 61.8337 49.2 60.0003 49.2H53.3337C51.967 49.2 50.8337 48.0667 50.8337 46.7C50.8337 45.3334 51.967 44.2 53.3337 44.2H60.0003C61.8337 44.2 63.3337 42.7 63.3337 40.8667V39.1667C63.3337 37.3333 61.8337 35.8333 60.0003 35.8333H53.3337C51.967 35.8333 50.8337 34.7333 50.8337 33.3333C50.8337 31.9333 51.967 30.8 53.3337 30.8Z"
        fill="white"
      />
      <path
        d="M23.3337 43.3333H20.0003C18.167 43.3333 16.667 41.8333 16.667 40C16.667 38.1667 18.167 36.6667 20.0003 36.6667H23.3337C25.167 36.6667 26.667 38.1667 26.667 40C26.667 41.8333 25.167 43.3333 23.3337 43.3333Z"
        fill="white"
      />
    </svg>
  );
};
export const CreateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        d="M73.333 20V28.0667C73.333 33.3333 69.9997 36.6667 64.733 36.6667H53.333V13.3667C53.333 9.66667 56.3663 6.63334 60.0663 6.66667C63.6997 6.7 67.033 8.16667 69.433 10.5667C71.833 13 73.333 16.3333 73.333 20Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M6.66699 23.3333V70C6.66699 72.7667 9.80033 74.3333 12.0003 72.6667L17.7003 68.4C19.0337 67.4 20.9003 67.5333 22.1003 68.7333L27.6337 74.3C28.9337 75.6 31.067 75.6 32.367 74.3L37.967 68.7C39.1337 67.5333 41.0003 67.4 42.3003 68.4L48.0003 72.6667C50.2003 74.3 53.3337 72.7333 53.3337 70V13.3333C53.3337 9.66667 56.3337 6.66667 60.0003 6.66667H23.3337H20.0003C10.0003 6.66667 6.66699 12.6333 6.66699 20V23.3333Z"
        fill="white"
      />
      <path
        d="M40 40.8667H30C28.6333 40.8667 27.5 42 27.5 43.3667C27.5 44.7333 28.6333 45.8667 30 45.8667H40C41.3667 45.8667 42.5 44.7333 42.5 43.3667C42.5 42 41.3667 40.8667 40 40.8667Z"
        fill="white"
      />
      <path
        d="M30 32.5333H40C41.3667 32.5333 42.5 31.4 42.5 30.0333C42.5 28.6667 41.3667 27.5333 40 27.5333H30C28.6333 27.5333 27.5 28.6667 27.5 30.0333C27.5 31.4 28.6333 32.5333 30 32.5333Z"
        fill="white"
      />
      <path
        d="M19.8997 26.7C18.0331 26.7 16.5664 28.2 16.5664 30.0333C16.5664 31.8667 18.0664 33.3667 19.8997 33.3667C21.7331 33.3667 23.2331 31.8667 23.2331 30.0333C23.2331 28.2 21.7331 26.7 19.8997 26.7Z"
        fill="white"
      />
      <path
        d="M19.8997 40.0333C18.0331 40.0333 16.5664 41.5333 16.5664 43.3667C16.5664 45.2 18.0664 46.7 19.8997 46.7C21.7331 46.7 23.2331 45.2 23.2331 43.3667C23.2331 41.5333 21.7331 40.0333 19.8997 40.0333Z"
        fill="white"
      />
    </svg>
  );
};
export const ScheduleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        d="M63.333 26.6667C68.8559 26.6667 73.333 22.1895 73.333 16.6667C73.333 11.1438 68.8559 6.66667 63.333 6.66667C57.8102 6.66667 53.333 11.1438 53.333 16.6667C53.333 22.1895 57.8102 26.6667 63.333 26.6667Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M63.3337 31.6667C55.067 31.6667 48.3337 24.9333 48.3337 16.6667C48.3337 14.2667 48.967 12.0333 49.967 10H25.067C13.567 10 6.66699 16.8667 6.66699 28.4V54.9C6.66699 66.4667 13.567 73.3333 25.067 73.3333H51.567C63.1003 73.3333 69.967 66.4667 69.967 54.9333V30.0333C67.967 31.0333 65.7337 31.6667 63.3337 31.6667Z"
        fill="white"
      />
    </svg>
  );
};
export const DrillIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M73.3337 30V50C73.3337 61.6667 66.667 66.6667 56.667 66.6667H28.167C29.3337 64.7 30.0003 62.4333 30.0003 60C30.0003 52.6333 24.0337 46.6667 16.667 46.6667C12.667 46.6667 9.10033 48.4333 6.66699 51.1999V30C6.66699 18.3333 13.3337 13.3333 23.3337 13.3333H56.667C66.667 13.3333 73.3337 18.3333 73.3337 30Z"
        fill="white"
      />
      <path
        d="M40.0003 48.3333C44.6027 48.3333 48.3337 44.6024 48.3337 40C48.3337 35.3976 44.6027 31.6667 40.0003 31.6667C35.398 31.6667 31.667 35.3976 31.667 40C31.667 44.6024 35.398 48.3333 40.0003 48.3333Z"
        fill="white"
      />
      <path
        d="M61.667 49.1667C60.3003 49.1667 59.167 48.0333 59.167 46.6667V33.3333C59.167 31.9667 60.3003 30.8333 61.667 30.8333C63.0337 30.8333 64.167 31.9667 64.167 33.3333V46.6667C64.167 48.0333 63.0337 49.1667 61.667 49.1667Z"
        fill="white"
      />
      <path
        d="M16.6663 46.6667C9.29967 46.6667 3.33301 52.6333 3.33301 60C3.33301 62.5 4.03296 64.8667 5.2663 66.8667C7.5663 70.7333 11.7997 73.3333 16.6663 73.3333C21.533 73.3333 25.7664 70.7333 28.0664 66.8667C29.2997 64.8667 29.9997 62.5 29.9997 60C29.9997 52.6333 24.033 46.6667 16.6663 46.6667ZM23.233 58.8999L16.133 65.4667C15.6663 65.9001 15.033 66.1334 14.433 66.1334C13.7996 66.1334 13.1663 65.9 12.6663 65.4L9.36633 62.1C8.39967 61.1334 8.39967 59.5334 9.36633 58.5667C10.333 57.6 11.933 57.6 12.8997 58.5667L14.4997 60.1666L19.833 55.2334C20.833 54.3 22.433 54.3666 23.3664 55.3666C24.2997 56.3666 24.233 57.9666 23.233 58.8999Z"
        fill="white"
      />
    </svg>
  );
};
