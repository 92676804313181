import React from 'react'
import Transaction from '../../components/Dashboard/Transaction';
// import NoTransaction from "../../components/Dashboard/NoTransaction"
// import Invoice from "../../components/Dashboard/Invoice"
import DashboardLayout from '../../Layouts/DashboardLayout';

const NairaHome = () => {
  
  return (
    <DashboardLayout>
      <Transaction />
    </DashboardLayout>
  );
}

export default NairaHome
