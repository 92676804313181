

import HomeComponent from "../../components/Demo/HomeComponent";

const OtherCountry = () => {
  return (
  
      <div className="w-full ">
        <HomeComponent />
      </div>
  
  );
};

export default OtherCountry;
