import React from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import DemoContent from '../../components/Demo/DemoContent'
const DemoHome = () => {
  return (
    <DashboardLayout>
      <div className="w-full flex">
        <DemoContent />
      </div>
    </DashboardLayout>
  );
}

export default DemoHome
