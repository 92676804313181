import { useEffect, useState, useRef, useMemo } from "react";
import { SearchIcon } from "../../assets/svgs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { resetQues, sendMessage } from "../../features/auth/AuthSlice";
import { Notification } from "../../assets/svgs";
import { FaTimes } from "react-icons/fa";
import NavDrop from "../Customs/NavDrop";
import ReUseModal from "../Modal/ReUseModal";
import CsvToPlainTextConverter from "../Convert/FileConvert";
import SideOverlay from "../Sideoverlay/Sideoverlay";
import siri from "../../assets/png/siri.png";
import { WhiteCancel } from "../../assets/svgs";
import baby from "../../assets/png/64px.png";
import { SendIcon } from "../../assets/svgs";
import loading from "../../assets/png/loading-animation.gif";
import sampA from "../../assets/png/upload-pic.png";
import { Btn } from "../Button";
export interface singleMessageProps {
  _id?: string;
  user?: string;
  body?: string;
  fromChat?: boolean;
  type?: string;
  createdAt: string;
  updatedAt: string;
  __v?: number;
}
const DemoNav = () => {
  const [open, setOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const allResp = [...auth?.allMessages].reverse();

  const [allMessages, setAllMessages] = useState<singleMessageProps[]>(allResp);

  const handleNewAnswer = (newAnswer: singleMessageProps) => {
    const rev = [...allMessages, newAnswer].reverse();
    setAllMessages(rev);
  };

  const [showRes, setShowRes] = useState(false);
  const [resp, setResp] = useState<any>({});
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("Result Here");
  const [dataQues, setDataQues] = useState<any>("");
  const [sentQues, setSentQues] = useState("");
  useEffect(() => {
    if (!auth.token) {
      window.location.replace("/login");
    }
  }, []);

  const revampQues = `Based on the uploaded data ${question}`;
  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlePost = () => {
    setSentQues(question);
    dispatch(resetQues());
    setAnswer("");
    const data = {
      body: dataQues ? dataQues : revampQues,
      type: dataQues ? "csv" : "text",
    };
    dispatch(sendMessage(data));
  };
  useEffect(() => {
    if (question) {
      setDataQues("");
    }
  }, [question]);

  useEffect(() => {
    if (auth?.searchResult?.body !== undefined || null) {
      setShowRes(true);
      setAnswer(auth?.searchResult?.body);
      setResp(auth?.searchResult);
      handleNewAnswer(auth?.searchResult);
      scrollToBottom();
      setOpen(false);
    } else {
      setShowRes(false);
    }
  }, [auth?.searchResult]);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handlePost();
      
    }
  };

  return (
    <div className="justify-center mt-10  w-full flex flex-col  ">
      <div className="w-full flex justify-between items-center">
        <div className="w-4/12 flex items-center py-[10px] px-4 bg-white space-x-2 ">
          <SearchIcon />
          <input
            type="text"
            className="bg-white outline-none border-none placeholder:text-sm text-[#364A5C] placeholder:text-[#364A5C] flex-grow"
            placeholder="Search for something...."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setQuestion(e.target.value)
            }
            onKeyDown={handleKeyPress}
          />
        </div>
        <div className="flex space-x-8 justify-end ">
          {/* <span
            className="cursor-pointer w-fit  "
            onClick={() => setOpenSidebar(true)}
          >
            <img src={rolex} alt="rolex" className="w-12 h-12" />
          </span> */}
          <Notification />
          <NavDrop open={open} setOpen={setOpen} />
          <div></div>
        </div>
      </div>

      <ReUseModal open={open} setOpen={setOpen}>
        <div className="w-full flex flex-col ">
          <h1 className="text-black text-[31px] mt-8 font-bold mb-8 ">
            Upload your file(s)
          </h1>
          <p className="text-sm text-black">Select file(s) to upload</p>
          <div className="flex flex-col  flow-hide gap-y-3 mt-8">
            <div
              className={`w-full min-h-[94px] h-[94px] flex items-center px-6 cursor-pointer justify-between border-border border border-dashed rounded-[4px] hover:bg-grey-10 relative  `}
            >
              {/* <input
                type="file"
                className="opacity-0 absolute inset-0 cursor-pointer"
              /> */}
            
                <CsvToPlainTextConverter setDataQues={setDataQues} />
              
              {/* left */}
              <div className="flex items-center gap-4">
                <span>
                  <img
                    src={sampA}
                    alt="samp"
                    className="w-10 h-auto rounded-[4px] "
                  />
                </span>
                <div className="flex flex-col">
                  <h4 className="text-coloured font-medium text-sm">
                    Select file(s) to upload
                  </h4>
                  <p className="text-sm text-muted">
                    click to select a file or drag and drop a file to upload
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
          
            <Btn
              name={auth?.loading ? "Loading..." : "Upload File"}
              active={true}
              onClick={handlePost}
            />
          </div>
        </div>
      </ReUseModal>
      <SideOverlay open={openSidebar} setOpen={setOpenSidebar}>
        <div className="w-full flex flex-col h-screen relative  ">
          <div className=" grad-bg w-full px-[70px] py-8   flex items-center justify-between h-[10vh] ">
            <div className="flex space-x-6 items-center">
              <img src={siri} alt="sir" />
              <p className="text-white text-[25px] font-semibold">
                Reelin Assistant
              </p>
            </div>
            <span
              className="cursor-pointer "
              onClick={() => setOpenSidebar(false)}
            >
              <WhiteCancel />
            </span>
          </div>
          <div className="flex justify-end h-[80vh] flow-hide">
            <div className="flex flex-col space-y-4 w-11/12 justify-self-end h-[80vh] pt-4">
              {sentQues && (
                <div className="w-full flex space-x-3 pb-8">
                  <img src={baby} alt="ctya" className="w-[40px] h-[40px]" />
                  <div
                    className={`flex-grow flex items-center flex-col space-y-3 p-3 bg-[#F2F4F5]`}
                  >
                    <p className="text-black text-base">{sentQues}</p>
                    <span className="text-sm text-[#818181]">
                      {new Date().toLocaleString()}
                    </span>
                  </div>
                </div>
              )}
              {/* response chats */}
              {answer && (
                <div className="w-full flex space-x-3 pb-8">
                  <img
                    src={resp.fromChat ? siri : baby}
                    alt="ctya"
                    className="w-[40px] h-[40px]"
                  />
                  <div
                    className={`flex-grow flex items-center flex-col space-y-3 p-3 ${
                      resp.fromChat ? "bg-[#D5E6FB]" : "bg-[#F2F4F5]"
                    }`}
                  >
                    <p className="text-black text-base">{answer}</p>
                    <span className="text-sm text-[#818181]">
                      {new Date().toLocaleString()}
                    </span>
                  </div>
                </div>
              )}
              {auth?.loading && (
                <div className="mt-6">
                  <img src={loading} alt="loader" />
                </div>
              )}
            </div>
          </div>

          <div
            className=" absolute left-0 right-0 bottom-0 z-10 p-6 justify-between items-center border-t border-t-slate-200 flex bg-white h-[10vh] "
            ref={bottomRef}
          >
            <input
              type="text"
              className="w-9/12 placeholder:text-base text-black outline-none border-none   "
              placeholder="What can the assistant help you with?"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setQuestion(e.target.value)
              }
              onKeyDown={handleKeyPress}
            />

            <span className="cursor-pointer" onClick={() => handlePost()}>
              <SendIcon />
            </span>
          </div>
        </div>
      </SideOverlay>
    </div>
  );
};

export default DemoNav;
