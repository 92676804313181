import React, { useCallback, useEffect, useState } from "react";

import { usePlaidLink, PlaidLinkOnSuccess } from "react-plaid-link";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { exchangeTokenPlaid, getToken, savePlaid } from "../../features/auth/AuthSlice";
import arrow from "../../assets/svgs/black-arrow-right.svg";

const SimplePlaidLink = () => {
    const auth = useAppSelector(state => state.auth);
  const [token, setToken] = useState<string | null>(auth?.token);
  const dispatch = useAppDispatch();
  const [publicToken, setPublicToken] = useState(
    auth?.plaidTokenData?.link_token
  );
  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    if (auth?.plaidTokenData?.link_token) {
      setToken(auth?.plaidTokenData?.link_token);
      setPublicToken(auth?.plaidTokenData?.link_token);
    }
  }, [auth?.plaidTokenData])


  

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    setMetaData(metaData);
    setPublicToken(publicToken);
    dispatch(savePlaid(publicToken));
    
    
  }, []);
  // the above is where we get to exchange the 🏬 data 


  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    // onEvent
    // onExit
  });



  return (
    <>
      <button
        onClick={() => open()}
        disabled={!ready}
        className="px-4 flex items-center bg cursor-pointer font-bold py-2 bg-background space-x-2 rounded-[5px] h-[41px] "
      >
        Connect with Plaid <span className="ml-2">
          <img src={arrow} alt="arrow" />
        </span>
      </button>
   
    </>
  );
};

export default SimplePlaidLink;
