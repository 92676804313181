// import { Importer, ImporterField } from "react-csv-importer";
import { useState, useEffect } from "react";
import CsvToPlainTextConverter from "./fileconverter";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllMessages, sendMessage } from "../../features/auth/AuthSlice";
import { resetQues } from "../../features/auth/AuthSlice";
const HomeComponent = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("Result Here");
  const [dataQues, setDataQues] = useState<any>("");
  const [allResp, setAllRes] = useState(auth?.allMessages);

  useEffect(() => {
    if (!auth.token) {
      window.location.replace("/login");
    }
  }, []);
  const revampQues = `Based on the uploaded data ${question}`;

  const handlePost = () => {
    dispatch(resetQues());
    setAnswer("");
    const data = {
      body: revampQues,
      type: "text",
    };
    dispatch(sendMessage(data));
  };
  const handleUpload = () => {
    dispatch(resetQues());
    setAnswer("");
    const data = {
      body: dataQues,
      type: "csv",
    };
    dispatch(sendMessage(data));
  };
  useEffect(() => {
    if (question) {
      setDataQues("");
    }
  }, [question]);

  useEffect(() => {
    if (auth?.searchResult) {
      setAnswer(auth?.searchResult?.body);
    }
  }, [auth?.searchResult]);

  console.log(allResp);

  return (
    <div className="w-full bg-[#f8f9fa]">
      <div className="w-full min-h-screen max-w-[1440px] bg-white xl:px-12 mx-auto">
        <div className="w-full flex flex-col lg:w-10/12 mx-auto ">
          <h1 className="text-[60px] font-bold text-black text-center mt-8">
            Reelin.ai
          </h1>
          <p className="text-center">
            Extract information from your financial and business operations data
            by asking simple questions
          </p>
          <div className="md:flex flex-col mt-4  space-y-4 px-4">
            <div className="flex flex-col ">
              <p className="font-bold">Steps:</p>
              <p className="lg:text-base text-sm">
                Step1: upload your excel sheets or CSV files
              </p>
              <p className="lg:text-base text-sm">Step2: question your data</p>
              <p className="lg:text-base text-sm">
                Step3: your life is now easier!
              </p>
            </div>
            <div className="flex flex-col ">
              <p className="font-bold">Question Samples:</p>
              <p className="lg:text-base text-sm">
                What product sold the most volume today?
              </p>
              <p className="lg:text-base text-sm">
                What’s the average revenue per user?
              </p>
            </div>
          </div>

          <div className="flex justify-between md:justify-center mt-8  md:space-x-12 items-center px-4  ">
            <CsvToPlainTextConverter setDataQues={setDataQues} />
            <button
              className="bg-primary   px-4 text-white py-2 rounded-xl cursor-pointer"
              onClick={handleUpload}
            >
              {auth?.loading ? "Loading..." : "Upload"}
            </button>
          </div>
          <div className="w-11/12 md:w-8/12 mx-auto mt-8 flex space-x-4">
            <input
              type="text"
              className="w-full  rounded-xl p-4 border border-slate-300 "
              placeholder=" Enter Message..."
              onChange={(e: any) => setQuestion(e.target.value)}
            />
            <button
              className="bg-primary   px-4 text-white py-2 rounded-xl cursor-pointer"
              onClick={handlePost}
            >
              {auth?.loading ? "Loading..." : "Send"}
            </button>
          </div>
        </div>
        <div className="w-full mt-8">
          {/* <XlsToPlainTextConverter /> */}
          {/* <XlsToPlainTextConverter setDataQues={setDataQues} /> */}
        </div>
        {/* annswer session */}

        <div className="px-4 w-full flex flex-col mt-6 ">
          <p className="text-black text-sm tracking-normal leading-7 px-4">
            {!auth.loading ? answer : "Please wait"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
