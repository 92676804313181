import React from 'react'
import { Link } from 'react-router-dom'
import Notification from "../../assets/svgs/notification.svg"
import Plus from "../../assets/svgs/white-plus.svg"
import Down from "../../assets/svgs/blue-arrow-down.svg"
import Download from "../../assets/svgs/arrow-download.svg"

function InvoicesList() {
  return (
    <div className='w-11/12 m-auto'>
      <div className="w-full flex justify-between items-center mt-12">
        <h1 className='text-[#828282] text-[31px] not-italic font-bold'>Invoices</h1>

        <img
        src={Notification}
        alt="notification icon"
        className="hover:bg-slate-300 rounded-full p-2 cursor-pointer"
        />
      </div>

      <div className="w-full h-20 mt-10 flex items-center justify-between">
        <ul className='flex w-[45%] justify-between text-[18px] font-medium not-italic'>
          <li className='text-[#2F80EC] border-b-2 border-[#2F80EC]'>Invoices</li>
          <li>Outstanding</li>
          <li>Paid</li>
          <li>Customers</li>
        </ul>

        <div className="w-[45%] flex justify-between">
          <div className="bg-[#E8E8FF] flex items-center justify-center self-center py-3 px-2 rounded-md text-[#2F80EC]">
            <p className="text-[14px] not-italic font-medium leading-normal text-right">All Status</p>
            <img src={Down} alt="down icon" />
          </div>

          <div className="bg-[#E8E8FF] flex items-center justify-center self-center py-3 px-2 rounded-md text-[#2F80EC]">
            <p className="text-[14px] not-italic font-medium leading-normal text-right">Download CSV</p>
            <img src={Download} alt="download icon" />
          </div>

          <Link to="/invoice/newinvoice" className="bg-[#2F80EC] flex items-center justify-center self-center py-3 px-2 rounded-md text-[#fff]">
            <img src={Plus} alt="plus icon" />
            <p className="text-[14px] not-italic font-medium leading-normal text-right">Create New Invoice</p>
          </Link>
        </div>

      </div>
        <div className="w-full mt-8">
          <table className='w-full justify-between not-italic'>
            <tr className='text-left text-[#818181] text-[16px] font-normal'>
              <th>Invoice</th>
              <th>Client</th>
              <th>Date</th>
              <th>Balance</th>
            </tr>
            <tr className='h-10'>
              <td>INV001</td>
              <td>Kwatmi Haruna Paul</td>
              <td>28th Jul, 2023</td>
              <td>$2,000,000.00</td>
            </tr>
            <tr className='h-10'>
              <td>INV001</td>
              <td>Kwatmi Haruna Paul</td>
              <td>28th Jul, 2023</td>
              <td>$2,000,000.00</td>
            </tr>
            <tr className='w-full h-10'>
              <td>INV001</td>
              <td>Kwatmi Haruna Paul</td>
              <td>28th Jul, 2023</td>
              <td>$2,000,000.00</td>
            </tr>
            <tr className='h-16'>
              <td></td>
              <td></td>
              <td className=''>Total Balance</td>
              <td className='font-semibold'>$22,000,000.00</td>
            </tr>
          </table>
        </div>
    </div>
  )
}

export default InvoicesList