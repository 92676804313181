import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import RecipientSidebar from '../../components/Sidebar/RecipientSidebar';
import cancel from "../../assets/svgs/close-circle.svg";
import {MdArrowForwardIos} from "react-icons/md";
import taguser from "../../assets/svgs/tag-user.svg";
import useradd from "../../assets/svgs/user-add.svg";
import star from "../../assets/svgs/star.svg";
import scanbarcode from "../../assets/svgs/scan-barcode.svg"

function NewRecipient() {
  const navigate = useNavigate()
  const HandleCancel = () => {
    navigate("/home/naira/")
  }
  return (
    <div className='w-full h-screen flex justify-between'>
      <RecipientSidebar />
      <div className="w-[80%] pt-10">
        <div className="w-11/12 m-auto flex justify-end items-center">
          <img
            onClick={HandleCancel}
            src={cancel}
            alt="cancel icon"
            className="hover:bg-slate-300 rounded-full p-3 cursor-pointer"
          />
        </div>

        <div className="2xl:w-2/4 max-w-[505px] w-full lg:w-6/12 m-auto border-border border rounded-[8px] px-8 pt-8 pb-12 flex flex-col mt-5">
         {/* center */}
         <Link to="/home/naira/new-recipient/tag" className="w-full mt-2 border border-border rounded-[6px] py-6 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <img src={taguser} alt="tag-user icon" />
            <div className="flex flex-col ml-[-3em]">
              <h1 className="text-black font-medium text-xl ">Using Allocate Tag</h1>
              <p className="text-secondary text-sm">
                Using email, user name or phone number.
              </p>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A]" />
          </Link>

          <Link to="/home/naira/new-recipient/recip" className="w-full mt-2 border border-border rounded-[6px] py-6 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <img src={useradd} alt="user-add icon" />
            <div className="flex flex-col">
              <h1 className="text-black font-medium text-xl ">New Recipient</h1>
              <p className="text-secondary text-sm">
              Business account for SME's and even corporates.
              </p>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A]" />
          </Link>

          <Link to="/home/naira/new-recipient/bene" className="w-full mt-2 border border-border rounded-[6px] py-6 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <img src={star} alt="star icon" />
            <div className="flex flex-col">
              <h1 className="text-black font-medium text-xl ">Save Beneficiaries</h1>
              <p className="text-secondary text-sm">
                Business account for SME's and even corporates.
              </p>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A]" />
          </Link>

          <Link to="/home/naira/new-recipient/qrcode" className="w-full mt-2 border border-border rounded-[6px] py-6 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <img src={scanbarcode} alt="scan barcode icon" />
            <div className="flex flex-col">
              <h1 className="text-black font-medium text-xl ">Upload Recipient’s QR Code</h1>
              <p className="text-secondary text-sm">
                Business account for SME's and even corporates.
              </p>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A]" />
          </Link>
          {/* END of  center */}
      </div>
      </div>
    </div>
  )
}

export default NewRecipient