// import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import vlogo from "../../assets/svgs/v-logo.svg";
import shop from "../../assets/svgs/shop.svg"
import profile from "../../assets/svgs/profile-circle.svg"
import {MdArrowForwardIos} from "react-icons/md"

const AccountSelection = () => {
  const navigate = useNavigate();
  const HandleClickPersonal = () => {
    navigate("/personal")
  }

  const HandleClickBusiness = () => {
    navigate("/business")
  }

  return (
    <div className="w-full h-screen flex items-center justify-center relative">
      <div className="absolute top-12 left-0 right-0 flex justify-center items-center">
        <img src={vlogo} alt="vlogo" />
      </div>
      <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border border rounded-[8px] px-8 pt-8 pb-12 flex flex-col ">
        <div className="flex flex-col mt-[35px]">
          <h1 className="text-black text-[31px] font-bold ">
            Let’s get started
          </h1>
          <p className="text-black text-base font-normal mt-2 mb-8">
            Select your sign up option
          </p>
          {/* center */}
          <div onClick={HandleClickPersonal} className="w-full border border-border rounded-[6px] py-6 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <img src={profile} alt="profile" />
            <div className="flex flex-col">
              <h1 className="text-black font-medium text-xl ">Personal</h1>
              <p className="text-secondary text-sm">
                Personal account for gigworkers and freelancers
              </p>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A]" />
          </div>

          <div onClick={HandleClickBusiness} className="w-full mt-2 border border-border rounded-[6px] py-6 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <img src={shop} alt="profile" />
            <div className="flex flex-col">
              <h1 className="text-black font-medium text-xl ">Business</h1>
              <p className="text-secondary text-sm">
                Business account for SME's and even corporates.
              </p>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A]" />
          </div>
          {/* END of  center */}
          <div className="mt-8 w-full flex justify-center space-x-1 items-center ">
            <p className="text-base text-secondary font-medium">
              Already have an account?
            </p>
            <Link to="/login" className="text-base text-primary font-medium">
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSelection;
