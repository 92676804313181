import React from 'react'
import { useNavigate } from 'react-router-dom';
import RecipientSidebar from '../../components/Sidebar/RecipientSidebar';
import cancel from "../../assets/svgs/close-circle.svg";
import ArrowRight from "../../assets/svgs/arrow-right.svg"
import {MdArrowForwardIos} from "react-icons/md";
import bxuser from "../../assets/svgs/bx_user.svg"
import bxuser1 from "../../assets/svgs/bx_user1.svg"
import bxuser2 from "../../assets/svgs/bx_user2.svg"
import bxuser3 from "../../assets/svgs/bx_user3.svg"

function SaveBene() {
  const navigate = useNavigate();

  const HandleCancel = () => {
      navigate("/home/naira/new-recipient")
  }
  return (
    <div className='w-full h-screen flex justify-between'>
        <RecipientSidebar />

        <div className="w-[80%] pt-10">

            <div onClick={HandleCancel} className="w-11/12 m-auto flex justify-end items-center">
                <img
                    src={cancel}
                    alt="cancel icon"
                    className="hover:bg-slate-300 rounded-full p-3 cursor-pointer"
                />
            </div>
            
            <div className='w-3/5 m-auto mt-5'>
                <div className="flex flex-col items-start gap-2 self-stretch text-[#000000] mt-8">
                    <h3 className='text-[31.25px] font-bold not-italic leading-normal'>Save Beneficiaries</h3>
                    <p className='text-[16px] not-italic font-normal leading-normal'>Select who are you sending the money to from the list</p>
                </div>

                {/* center */}
                  <div className="w-11/12 relative mt-5">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                      </svg>
                    </div>
                    <input type="search" className="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 outline-none" placeholder="Find Beneficiary" required />
                  </div>

                  <div className="w-11/12 mt-3 h-64 overflow-y-scroll">
                    <div className="w-full mt-2 border border-border rounded-[6px] py-1 px-4 items-center justify-between flex cursor-pointer">
                      <div className="flex">
                        <img src={bxuser} alt="user icon" />
                          <div className="flex flex-col ml-3">
                            <h1 className="text-black font-medium text-xl ">Kwatmi Tyron</h1>
                            <p className="text-secondary text-sm">Allocate Account</p>
                          </div>
                        </div>
                        <MdArrowForwardIos className="text-[#8C8A8A]" />
                    </div>

                    <div className="w-full mt-2 border border-border rounded-[6px] py-1 px-4 items-center justify-between flex cursor-pointer">
                      <div className="flex">
                        <img src={bxuser2} alt="user icon" />
                          <div className="flex flex-col ml-3">
                            <h1 className="text-black font-medium text-xl ">Okarafor Okafor</h1>
                            <p className="text-secondary text-sm">Allocate Account</p>
                          </div>
                        </div>
                        <MdArrowForwardIos className="text-[#8C8A8A]" />
                    </div>

                    <div className="w-full mt-2 border border-border rounded-[6px] py-1 px-4 items-center justify-between flex cursor-pointer">
                      <div className="flex">
                        <img src={bxuser1} alt="user icon" />
                          <div className="flex flex-col ml-3">
                            <h1 className="text-black font-medium text-xl ">Ibrahim Musa Damisa</h1>
                            <p className="text-secondary text-sm">Acct No: 23434343434</p>
                          </div>
                        </div>
                        <MdArrowForwardIos className="text-[#8C8A8A]" />
                    </div>

                    <div className="w-full mt-2 border border-border rounded-[6px] py-1 px-4 items-center justify-between flex cursor-pointer">
                      <div className="flex">
                        <img src={bxuser3} alt="user icon" />
                          <div className="flex flex-col ml-3">
                            <h1 className="text-black font-medium text-xl ">Kaleb Bromaha</h1>
                            <p className="text-secondary text-sm">Allocate Account</p>
                          </div>
                        </div>
                        <MdArrowForwardIos className="text-[#8C8A8A]" />
                    </div>

                    <div className="w-full mt-2 border border-border rounded-[6px] py-1 px-4 items-center justify-between flex cursor-pointer">
                      <div className="flex">
                        <img src={bxuser} alt="user icon" />
                          <div className="flex flex-col ml-3">
                            <h1 className="text-black font-medium text-xl ">Kaleb Bromaha</h1>
                            <p className="text-secondary text-sm">Acct No: 23434343434</p>
                          </div>
                        </div>
                        <MdArrowForwardIos className="text-[#8C8A8A]" />
                    </div>
                  </div>
                {/* END of  center */}
                <div className="flex justify-end">
                  <div className="bg-[#fff] border border-[#2F80EC] rounded-md flex justify-center items-center h-10 px-2 text-center mt-5 cursor-pointer">
                    <p className='text-[#2F80EC]'>Add New Beneficiary</p>
                  </div>

                  <div className="flex justify-between items-center bg-[#2F80EC] rounded-md w-[90px] h-10 px-2 text-center mt-5 cursor-pointer ml-6">
                    <p className='text-[#fff]'>Next</p>
                    <img src={ArrowRight} alt="arrow right icon" />
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SaveBene