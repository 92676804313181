import { useState } from "react";
import { MainLogo } from "../../assets/svgs";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import SelectAccount from "../../components/onboarding-component/SelectAccount";
import SelectDataSource from "../../components/onboarding-component/SelectDataSource";
import UploadDocument from "../../components/onboarding-component/UploadDocument";
import UploadProgress from "../../components/onboarding-component/UploadProgress";
import UploadComplete from "../../components/onboarding-component/UploadComplete";
const ChooseAccount = () => {
  const dispatch = useAppDispatch();

    const auth = useAppSelector((state) => state.auth);
    const [present, setPresent] = useState(1);

  return (
    <div className="w-full min-h-screen flow-hide  flex flex-col items-center justify-center relative pb-10 bg-background">
      <div className=" flex justify-center items-center">
        <MainLogo />
      </div>

      {
        present === 1 && (
          <SelectAccount setPresent={setPresent} />
        )
        }
      {
        present === 2 && (
          <SelectDataSource setPresent={setPresent} />
        )
        }
      {
        present === 3 && (
          <UploadDocument setPresent={setPresent} />
        )
        }
      {
        present === 4 && (
          <UploadProgress setPresent={setPresent} />
        )
        }
      {
        present === 5 && (
          <UploadComplete setPresent={setPresent} />
        )
        }
    </div>
  );
};

export default ChooseAccount;
