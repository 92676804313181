import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import APIService from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
export interface AuthState {
  loading: boolean;
  token: string;
  error?: boolean;
  success: boolean;
  allBanks: any;
  saveUserTagSuccess: boolean;
  allSavedBene: any;
  verifyAccountNumberDetail: any;
  deleteRecordSuccess: boolean;
  searchUserResult: any;

}

const initialState: AuthState = {
  loading: false,
  token: "",
  error: false,
  success: false,
  allBanks: [],
  saveUserTagSuccess: false,
  allSavedBene: [],
  verifyAccountNumberDetail: [],
  deleteRecordSuccess: false,
  searchUserResult:[]
  
  
};

export const beneficiariesSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;

    },
    logoutResetAll: (state) => {
      state.loading = false;
  
    },
    resetAll: (state) => {
      state.loading = false;
    },
   
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllBanks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBanks.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allBanks = payload?.data?.banks?.data
    
      })
      .addCase(getAllBanks.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        state.allBanks = [];
      
      })
      .addCase(saveUserTag.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveUserTag.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.saveUserTagSuccess = true
    
      })
      .addCase(saveUserTag.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        state.saveUserTagSuccess = false;
      })
      .addCase(verfiyAccNo.pending, (state) => {
        state.loading = true;
      })
      .addCase(verfiyAccNo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.verifyAccountNumberDetail = payload?.data?.account
    
      })
      .addCase(verfiyAccNo.rejected, (state, { payload }) => {
        state.loading = false;
        
        state.verifyAccountNumberDetail = [];
      })
      .addCase(deleteRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRecord.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.deleteRecordSuccess = true;
    
      })
      .addCase(deleteRecord.rejected, (state, { payload }) => {
        state.loading = false;
        
        state.deleteRecordSuccess = false;
      })
      .addCase(searchUserTag.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchUserTag.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.searchUserResult = payload?.data;
    
      })
      .addCase(searchUserTag.rejected, (state, { payload }) => {
        state.loading = false;
        
        state.searchUserResult = [];
      })

      
      
      ;
    // end of session

  },
});



export const saveUserTag = createAsyncThunk(
  "saveUserTag",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.saveBene}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
); 

export const deleteRecord = createAsyncThunk(
  "deleteRecord",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.delete(`${url.saveBene}/${payload?.id}`,  {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
); 
export const verfiyAccNo = createAsyncThunk(
  "verifyAccNO",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.verifyAccount}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
); 
export const getSavedBene = createAsyncThunk(
  "getSavedBene",
  async (_: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.saveBene}`,  {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
); 

export const getAllBanks = createAsyncThunk(
  "getAllBanks",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allBanks}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const searchUserTag = createAsyncThunk(
  "searchUserTag",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.searchUser}?keyword=${payload}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);


export const beneficiariesSelector = (state: any) => state.auth;

export const { restoreDefault, resetAll } = beneficiariesSlice.actions;
export default beneficiariesSlice.reducer;
