import React from 'react'
import { useNavigate } from 'react-router-dom';
import RecipientSidebar from '../../components/Sidebar/RecipientSidebar';
import cancel from "../../assets/svgs/close-circle.svg";
import ArrowRight from "../../assets/svgs/arrow-right.svg"
import scanbarcode from "../../assets/svgs/scan-barcode.svg"

function ScanQrcode() {
    const navigate = useNavigate();

    const HandleCancel = () => {
        navigate("/home/naira/new-recipient")
    }
  return (
    <div className='w-full h-screen flex justify-between'>
        <RecipientSidebar />

        <div className="w-[80%] pt-10">

            <div onClick={HandleCancel} className="w-11/12 m-auto flex justify-end items-center">
                <img
                    src={cancel}
                    alt="cancel icon"
                    className="hover:bg-slate-300 rounded-full p-3 cursor-pointer"
                />
            </div>
            
            <div className='w-3/5 m-auto mt-16'>
                <div className="text-[#000000] mt-8 text-center">
                    <h3 className='text-[31.25px] font-bold not-italic leading-normal'>Upload Recipient’s QR Code</h3>
                </div>

                {/* center */}
                <div className="w-full border border-border rounded-[6px] py-6 mt-8 px-4 justify-between flex flex-col text-center">
                    <img src={scanbarcode} alt="scan-barcode icon" className='w-2/12 my-5 m-auto' />
                    <p className='text-[14px] not-italic font-normal'>Drag and drop file or <span className='text-[#2F80EC] cursor-pointer'>click here</span> to Upload from you local files</p>
                </div>
                {/* END of  center */}

                <div className="flex justify-between items-center bg-[#2F80EC] rounded-md w-[90px] h-10 px-2 text-center mt-5 cursor-pointer relative left-[80%]">
                    <p className='text-[#fff]'>Next</p>
                    <img src={ArrowRight} alt="arrow right icon" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default ScanQrcode