import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Btn } from "../../components/Button";
import { NormalInput } from "../../components/Input";
import mail from "../../assets/svgs/sms.svg";
import eye from "../../assets/svgs/eye-slash.svg";
import { MainLogo } from "../../assets/svgs";
import { login, logoutResetAll, requestOtp, resetAll } from "../../features/auth/AuthSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import USlogo from "../../assets/svgs/us.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  useEffect(() => {
    dispatch(resetAll());
    dispatch(logoutResetAll());
  }, []);
  const handleSubmit = () => {
    const data = {
      email: email,
      password: password,
    };
    dispatch(login(data));
  };

  useEffect(() => {
    if (auth.token && auth?.userData?.user?._email === true) {
      window.location.replace("/dashboard");
    } else if (auth.token && auth?.userData?.user?._email === false) {

       const data = {
         email: auth?.userData?.user?.email || "",
       };

       dispatch(requestOtp(data));
    }
  }, [auth?.token]);
   useEffect(() => {
     if (auth?.reqOtpSuccess) {
       toast.success("Otp sent successfully");
       dispatch(resetAll());
    
       setTimeout(() => {
         navigate("/otp");
       }, 2000);
     }
   }, [auth?.reqOtpSuccess]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center relative bg-background">
      <div className="  flex justify-center items-center">
        <MainLogo />
      </div>
      <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border border rounded-[8px] px-8 pt-8 pb-12 flex flex-col bg-white ">
        {/* <div className="flex space-x-3 items-center cursor-pointer">
          <img src={arrow} alt="arrow" />
          <p className="text-primary text-sm">Back</p>
        </div> */}
        <div className="flex flex-col mt-[35px]">
          <h1 className="text-black text-[31px] font-bold mb-8 ">Login</h1>
          {/* <p className="text-black text-base font-normal mt-2 mb-8">
            Business Account
          </p> */}
          <NormalInput
            onChange={(e: any) => setEmail(e.target.value)}
            type={"email"}
            name="Email"
            placeholder="example@mail.com"
            src={mail}
          />
          <NormalInput
            onChange={(e: any) => setPassword(e.target.value)}
            type={"password"}
            name="Password"
            placeholder="*********"
            src={eye}
            className="mt-5"
          />
          <div className="w-full flex justify-end items-center mt-2">
            <Link
              to="/forgot-password"
              className="text-customBlack text-sm font-normal cursor-pointer"
            >
              Reset Password
            </Link>
          </div>
          <div className="mt-8 w-full flex justify-end items-center ">
            <Btn
              name={auth?.loading ? "Loading...." : "Continue"}
              active={true}
              onClick={handleSubmit}
            />
          </div>
          <div className="mt-8 w-full flex justify-center space-x-1 items-center ">
            <p className="text-base text-secondary font-medium">
              Don’t have an account?
            </p>
            <Link
              to="/register"
              className="text-base text-customBlack font-medium"
            >
              Register
            </Link>
          </div>
        </div>
      </div>
      <div className="w-fit mx-auto flex items-center gap-3 mt-8">
        <img src={USlogo} alt="us-flag" className="w-[18px] h-[18px]" />
        <p className="text-navgrey text-sm">Reelin is optimized for US users only.</p>
      </div>
    </div>
  );
};

export default Login;
