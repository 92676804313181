import { FiSettings } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";

import { AiOutlineLogout } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../../assets/svgs/v-logo.svg";
import { useNavigate } from "react-router-dom";
import {
  PaymentIcon,
  InvoicingIcon,
  ExpenseIcon,
  AnalyticsIcon,
  AccountIcon,
  HomeIcon,
  MainLogo,
} from "../../assets/svgs";
const styles = {
  active:
    "bg-[#EAF2FD] h-[42px] py-3 px-5 gap-[10px]  flex items-center text-primary text-sm  cursor-pointer ",
  inactive:
    "flex items-center hover:bg-[#EAF2FD] py-3 px-5 gap-[10px] text-sm text-[#818181] rounded-l-[5px] hover:text-primary cursor-pointer  ",
};
import { useAppDispatch } from "../../app/hooks";
import { logoutResetAll } from "../../features/auth/AuthSlice";
const Sidebar = ({ current }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logout = () => {
    dispatch(logoutResetAll());
    window.location.replace("/login");
  

  }
  return (
    <div className="w-full fixed top-0 left-0 bottom-0 h-screen flex flex-col max-w-[250px]  border-r bg-white flow-hide  ">
      <div className="w-full flex justify-center mt-12 mb-10">
        {/* <img alt="logo" src={logo} /> */}
        <span className="mx-auto">
          <MainLogo />
        </span>
      </div>
      <div className="flex flex-col pl-4">
        {/* <p className="text-[#082431] text-xs opacity-50 my-6 pl-6">MENU</p> */}
        <div className="flex flex-col space-y-1">
          {/* start of a single item */}
          <div
            className={current === 1 ? styles.active : styles.inactive}
            onClick={() => navigate("/dashboard")}
          >
            <HomeIcon />
            <p>Home</p>
          </div>
          {/* end of a single link item */}
          {/* start of a single item */}
          {/* <div className={current === 2 ? styles.active : styles.inactive}>
            <PaymentIcon />
            <p>Payment</p>
          </div> */}
          {/* end of a single link item */}
          {/* start of a single item */}
          {/* <Link
            to="/dashboard"
            className={current === 3 ? styles.active : styles.inactive}
          >
            <InvoicingIcon />
            <p>Invoices</p>
          </Link> */}
          {/* end of a single link item */}
          {/* start of a single item */}
          <div className={current === 4 ? styles.active : styles.inactive}>
            <ExpenseIcon />
            <p>Documents</p>
          </div>
          {/* end of a single link item */}
          <div className={current === 6 ? styles.active : styles.inactive}>
            <AccountIcon />
            <p>Account</p>
          </div>
          {/* start of a single item */}
          {/* <Link
            to={
              "https://app.powerbi.com/view?r=eyJrIjoiYzMxMzNmOWQtMTRjYS00YTM3LWFiZGEtNjI1YWU3MDNlNmZlIiwidCI6Ijc0Y2NkNjY2LWZiYTctNDBhNi1hYmVjLWUyYTBmM2EwYzI2NiJ9&pageName=ReportSection"
            }
            className={current === 5 ? styles.active : styles.inactive}
          >
            <AnalyticsIcon />
            <p>Analytics</p>
          </Link> */}
          {/* end of a single link item */}
        </div>
      </div>
      {/* END OF THE UPPER SESSION OF THE SIDE BAR ############################################################################# */}
      <div className="flex flex-col pl-4 mt-10">
        <p className="text-[#082431] text-xs opacity-50 my-6 pl-6">OTHERS</p>
        <div className="flex flex-col space-y-1">
          {/* start of a single item */}

          <div className={current === 6 ? styles.active : styles.inactive}>
            <FiSettings className="text-lg" />
            <p>Settings</p>
          </div>

          <div className={current === 8 ? styles.active : styles.inactive}>
            <AiOutlineUser className="text-lg" />
            <p>Profile</p>
          </div>

          <div
            className={current === 10 ? styles.active : styles.inactive}
            onClick={logout}
          >
            <AiOutlineLogout className="text-lg" />
            <p>Logout</p>
          </div>
          {/* end of a single link item */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
