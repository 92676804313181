import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
// import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import CreateNewPassword from "./pages/auth/CreateNewPassword";
import OtpVerification from "./pages/auth/OtpVerfication";
import AccountSelection from "./pages/auth/AccountSelection";
import Home from "./pages/Dashboard/Home";
import PersonalSignup from "./pages/auth/PersonalSignup";
import BusinessSignup  from "./pages/auth/BusinessSignup ";
import PersonalBvn from "./pages/auth/PersonalBvn";
import PersonalNin from "./pages/auth/PersonalNin";
import PersonalNumber from "./pages/auth/PersonalNumber";
import PersonalEmail from "./pages/auth/PersonalEmail";

import NairaHome from "./pages/Dashboard/NairaHome";
import NewRecipient from "./pages/Dashboard/NewRecipient";
import UsingTag from "./pages/Dashboard/UsingTag";
import New from "./pages/Dashboard/New";
import SaveBene from "./pages/Dashboard/SaveBenef";
import ScanQrcode from "./pages/Dashboard/ScanQrcode";
import Invoice from "./pages/Dashboard/Invoice";
import NewInvoice from "./pages/Dashboard/NewInvoice";
import InvoiceDetails from "./pages/Dashboard/InvoiceDetails";
import Payment from "./pages/Dashboard/Payment";
import InvoiceInformation from "./components/Dashboard/InvoiceInformation";

import NewLogin from "./pages/auth/TempLogin"
import DemoHome from "./pages/Dashboard/DemoHome";
import OtherCountry from "./pages/demo/OtherCountry";
import RevampReg from "./pages/auth/RevampRegister";
import VerifyEmail from "./pages/auth/VerifyEmial";
import ConnectPlaid from "./pages/onboarding/connect-plaid";
import ChooseAccount from "./pages/onboarding/choose-account";
const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register-s" element={<AccountSelection />} />
        <Route path="/login-s" element={<NewLogin />} />
        <Route path="home" element={<Home />} />
        <Route path="dashboard" element={<DemoHome />} />
        <Route path="dashboard/others" element={<OtherCountry />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/register" element={<RevampReg />} />
        <Route path="/connect-account" element={<ConnectPlaid />} />
        <Route path="/choose-account" element={<ChooseAccount />} />

        {/* NOT IMPORTANT */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="new-password" element={<CreateNewPassword />} />
        <Route path="otp" element={<OtpVerification />} />
        <Route path="personal" element={<PersonalSignup />} />
        <Route path="personal/bvn" element={<PersonalBvn />} />
        <Route path="personal/nin" element={<PersonalNin />} />
        <Route path="personal/number" element={<PersonalNumber />} />
        <Route path="personal/email" element={<PersonalEmail />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="business" element={<BusinessSignup />} />
        <Route path="payment" element={<Payment />} />
        <Route path="invoice" element={<Invoice />} />
        <Route path="invoice/newinvoice" element={<NewInvoice />} />
        <Route path="invoice/invoicedetails" element={<InvoiceDetails />} />
        <Route path="invoice/Invoiceinformation" element={<InvoiceInformation />} />
        <Route path="home/naira" element={<NairaHome />} />
        <Route path="home/naira/new-recipient" element={<NewRecipient />} />
        <Route path="home/naira/new-recipient/tag" element={<UsingTag />} />
        <Route path="home/naira/new-recipient/recip" element={<New />} />
        <Route path="home/naira/new-recipient/bene" element={<SaveBene />} />
        <Route path="home/naira/new-recipient/qrcode" element={<ScanQrcode />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
