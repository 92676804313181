import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Btn } from "../../components/Button";
import { NormalInput } from "../../components/Input";
import user from "../../assets/svgs/user.svg"
import mail from "../../assets/svgs/sms.svg";
import eye from "../../assets/svgs/eye-slash.svg";
import { MainLogo } from "../../assets/svgs";
import { logoutResetAll, personalReg, requestOtp, resetAll } from "../../features/auth/AuthSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import arrow from "../../assets/svgs/arrow-left.svg"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import check from "../../assets/png/sign-up-check.png"
const RevampReg = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
 const auth = useAppSelector((state) => state.auth);
 const [name, setName] = useState<string>("");
 const [password, setPassword] = useState<string>("");
 const [email, setEmail] = useState<string>("");
 const [username, setUsername] = useState<string>("");
 const [country, setCountry] = useState<string>("");
  const [isNigeria, setIsNigeria] = useState<boolean>(true);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  

 useEffect(() => {
   let c = country.toLocaleLowerCase();
   if (c === "nigeria") {
     setIsNigeria(true);
   } else setIsNigeria(false);
 }, [country]);
function generateRandomUsername(name: string): string {
  // Convert name to lowercase and remove spaces
  const formattedName = name.toLowerCase().replace(/\s/g, "");

  // Generate a random number suffix
  const randomNumber = Math.floor(Math.random() * 10000);

  // Combine name with a random number suffix to create the username
  const username = `${formattedName}${randomNumber}`;

  return username;
}
 const handleSubmit = () => {
   const data = {
     email: email,
     password: password,
     name: name,
     country: "United States of America",
     isNigeeria: false,
     tag: generateRandomUsername(name),
   };
   dispatch(personalReg(data));
 };

  useEffect(() => {
    dispatch(resetAll());
    dispatch(logoutResetAll());
  }, []);
 useEffect(() => {
   if (auth.token && auth?.userData?.user?.country === "nigeria") {
    //  window.location.replace("/dashboard");
     const data = {
       email:auth?.userData?.user?.email || "",
     }

     dispatch(requestOtp(data));
   

   } else if (auth.token && auth?.userData?.user?.country !== "nigeria") {
     //  window.location.replace("/dashboard");
      const data = {
        email: auth?.userData?.user?.email || "",
      };

     dispatch(requestOtp(data));
      
   }
 }, [auth?.token]);
  
  
  
  useEffect(() => {
    if (auth?.reqOtpSuccess) {
      toast.success("Otp sent successfully")
      dispatch(resetAll());
      setRegisterSuccess(true);
      setTimeout(() => {
        
        navigate("/otp");
      }, 2000);
    }
  }, [auth?.reqOtpSuccess])
  



  return (
    <div className="w-full h-screen flex flex-col items-center justify-center relative pb-10 bg-background">
      <div className=" flex justify-center items-center">
        <MainLogo />
      </div>
      {registerSuccess ? (
        <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border bg-white border rounded-[8px] px-8 pt-8 pb-12 flex flex-col ">
          <div className="flex space-x-3 items-center cursor-pointer">
            <img src={arrow} alt="arrow" />
            <p className="text-[#9D9D9D] text-sm">Back</p>
          </div>
          <div className="flex flex-col mt-[35px]">
            <h1 className="text-black text-[31px] font-bold mb-8 ">
              Account created successfully
            </h1>
            {/* <p className="text-black text-base font-normal mt-2 mb-8">
            Business Account
          </p> */}
            <p className="text-sm text-black">
              Unlock the full potential of Reelin. We analyze transactions for deeper insights only without the access to perform any transactions on your accounts. So it’s totally save 😉
            </p>
            <div className="mt-8 pb-12 flex items-center justify-center">
              <img src={check} alt="check" />
            </div>
         
          
          </div>
        </div>
      ) : (
        <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border bg-white border rounded-[8px] px-8 pt-8 pb-12 flex flex-col ">
          {/* <div className="flex space-x-3 items-center cursor-pointer">
          <img src={arrow} alt="arrow" />
          <p className="text-primary text-sm">Back</p>
        </div> */}
          <div className="flex flex-col mt-[35px]">
            <h1 className="text-black text-[31px] font-bold mb-8 ">Sign Up</h1>
            {/* <p className="text-black text-base font-normal mt-2 mb-8">
            Business Account
          </p> */}
            <NormalInput
              onChange={(e: any) => setName(e.target.value)}
              type={"text"}
              name="Name"
              placeholder="your name"
              src={user}
            />

            <NormalInput
              onChange={(e: any) => setEmail(e.target.value)}
              type={"email"}
              name="Email"
              placeholder="example@mail.com"
              src={mail}
            />
            <NormalInput
              onChange={(e: any) => setPassword(e.target.value)}
              type={"password"}
              name="Password"
              placeholder="*********"
              src={eye}
              className="mt-5"
            />

            <div className="mt-8 w-full flex justify-end items-center ">
              <Btn
                name={auth?.loading ? "Loading...." : "Sign Up"}
                active={true}
                onClick={handleSubmit}
              />
            </div>
            <div className="mt-8 w-full flex justify-center space-x-1 items-center ">
              <p className="text-base text-secondary font-medium">
                Already have an account?
              </p>
              <Link
                to="/login"
                className="text-base text-customBlack font-medium"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RevampReg;
