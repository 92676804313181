import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import APIService, {
  BotAPIService,
  nAPIService,
} from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
export interface AuthState {
  loading: boolean;
  token: string;
  error?: boolean;
  success: boolean;
  searchResult: any;
  allMessages: any;
  personalRegSuccess: boolean;
  userData: any;
  businessRegSuccess: boolean;
  reqOtpSuccess: boolean;
  verifyOtpSuccess: boolean;
  loginSuccess: boolean;
  resetPasswordSuccess: boolean;
  validateResetTokenSuccess: boolean;
  newPasswordSuccess: boolean;
  logoutSuccess: boolean;
  plaidTokenData: any;
  plaidInfo: any;
  plaidData: string;
}

const initialState: AuthState = {
  loading: false,
  token: "",
  error: false,
  success: false,
  searchResult: {},
  allMessages: [],
  personalRegSuccess: false,
  userData: [],
  businessRegSuccess: false,
  reqOtpSuccess: false,
  verifyOtpSuccess: false,
  loginSuccess: false,
  resetPasswordSuccess: false,
  validateResetTokenSuccess: false,
  newPasswordSuccess: false,
  logoutSuccess: false,
  plaidTokenData: {},
  plaidInfo: {},
  plaidData: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
      state.token = "";
    },
    logoutResetAll: (state) => {
      state.loading = false;
      state.userData = [];
      state.token = "";
      state.loginSuccess = false;
      state.success = false;
      state.personalRegSuccess = false;
      state.businessRegSuccess = false;
    },
    resetAll: (state) => {
      state.loading = false;
      state.reqOtpSuccess = false;
      state.businessRegSuccess = false;
      state.resetPasswordSuccess = false;
      state.verifyOtpSuccess = false;
      state.newPasswordSuccess = false;
    },
    resetQues: (state) => {
      state.searchResult = {};
    },
    resetSavedPlaid: (state) => {
      state.plaidData = "";
    },
    savePlaid: (state, action: PayloadAction<any>) => {
      state.plaidData = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder

      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.token = payload?.token;
        state.userData = payload;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.token = "";
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.token = payload?.token;
        state.userData = payload;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.token = "";
      })
      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendMessage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.searchResult = payload?.prompt;
      })
      .addCase(sendMessage.rejected, (state, { payload }) => {
        state.loading = false;
        state.searchResult = {};
      })
      .addCase(getAllMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMessages.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allMessages = payload?.prompt;
      })
      .addCase(getAllMessages.rejected, (state, { payload }) => {
        state.loading = false;
        state.allMessages = [];
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.token = payload?.token;
        state.userData = payload;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false;
        state.userData = [];
        state.token = "";
      })
      .addCase(personalReg.pending, (state) => {
        state.loading = true;
      })
      .addCase(personalReg.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.token = payload?.token;
        state.userData = payload;
        state.personalRegSuccess = true;
      })
      .addCase(personalReg.rejected, (state, { payload }) => {
        state.loading = false;
        state.userData = [];
        state.token = "";
        state.personalRegSuccess = false;
      })
      .addCase(businessReg.pending, (state) => {
        state.loading = true;
      })
      .addCase(businessReg.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.token = payload?.token;
        state.businessRegSuccess = true;
      })
      .addCase(businessReg.rejected, (state, { payload }) => {
        state.loading = false;
        state.userData = [];
        state.token = "";
        state.businessRegSuccess = false;
      })
      .addCase(requestOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestOtp.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.reqOtpSuccess = true;
      })
      .addCase(requestOtp.rejected, (state, { payload }) => {
        state.loading = false;

        state.reqOtpSuccess = false;
      })

      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.verifyOtpSuccess = true;
        state.token = payload?.token;
        state.userData = payload?.user;
      })
      .addCase(verifyOtp.rejected, (state, { payload }) => {
        state.loading = false;

        state.verifyOtpSuccess = false;
      })
      .addCase(validateST.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateST.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.validateResetTokenSuccess = true;
      })
      .addCase(validateST.rejected, (state, { payload }) => {
        state.loading = false;

        state.validateResetTokenSuccess = false;
      })
      .addCase(newPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(newPassword.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.userData = payload?.user;
        state.token = payload?.token;
      })
      .addCase(newPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        state.token = "";
        state.userData = [];
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.resetPasswordSuccess = true;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        state.resetPasswordSuccess = false;
      })
      .addCase(getToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(getToken.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.plaidTokenData = payload?.data;
      })
      .addCase(getToken.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        state.plaidTokenData = {};
      })
      .addCase(exchangeTokenPlaid.pending, (state) => {
        state.loading = true;
      })
      .addCase(exchangeTokenPlaid.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.plaidInfo = payload;
      })
      .addCase(exchangeTokenPlaid.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        state.plaidInfo = {};
      });
    // end of session
  },
});

export const loginUser = createAsyncThunk(
  "loginUser",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.samplogin}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const login = createAsyncThunk(
  "login",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.login}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const registerUser = createAsyncThunk(
  "registerUser",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.register}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const personalReg = createAsyncThunk(
  "presonalReg",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.personalReg}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
// export const getToken = createAsyncThunk(
//   "getToken",
//   async (_, { rejectWithValue, getState }) => {
//     const { auth }: any = getState();
//     try {
//       const { data } = await APIService.post(`${url.getToken}`, {
//         headers: {
//           Authorization: `Bearer ${auth?.token}`,
//         }
//       });
//       return data;
//     } catch (error: any) {
//       return rejectWithValue(
//         getSimplifiedError(error.response ? error : error)
//       );
//     }
//   }
// );
export const getToken = createAsyncThunk(
  "getToken",
  async (_, { rejectWithValue, getState, dispatch }) => {
    const { auth }: any = getState();

    try {
      let req = {
        method: "post",
        address: `${url.getToken}`,
        header: `Authorization: Bearer ${auth?.token}`,
      };
      const { data } = await nAPIService(req.method, req.address, req.header);

      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const businessReg = createAsyncThunk(
  "businessReg",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.businessReg}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const sendMessage = createAsyncThunk(
  "sendMessage",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await BotAPIService.post(`${url.message}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);

export const exchangeTokenPlaid = createAsyncThunk(
  "exchangeTokenPlaid",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.exchangeToken}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const requestOtp = createAsyncThunk(
  "requestOtp",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.reqOtp}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.verifyOtp}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const validateST = createAsyncThunk(
  "validate Secret Token",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.vst}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const { data } = await APIService.post(`${url.resetPassword}`, payload);
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const newPassword = createAsyncThunk(
  "new Password",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.newPassword}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);

export const getAllMessages = createAsyncThunk(
  "getAllMessage",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.message}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);

export const authSelector = (state: any) => state.auth;

export const { restoreDefault, resetAll, resetQues, logoutResetAll,savePlaid, resetSavedPlaid } =
  authSlice.actions;
export default authSlice.reducer;
