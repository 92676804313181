import React from 'react'
import DemoNav from './DemoNav';
import Tip from './Tip';
import Stats from './Stats';
import graph from "../../assets/png/graph.png";

import planning from "../../assets/png/planning.png";
import trans from "../../assets/png/transactions.png";
import ExpensesChart from '../Charts/expenses-chart';
import IncomeChart from '../Charts/income-chart';
import BotContainer from '../AiBot/BotContainer';
const DemoContent = () => {
    return (
      <div className="w-full bg-[#F7F7F7] min-h-screen h-full flex flex-col ">
        <div className="w-full px-8 mx-auto flex flex-col relative">
          <div className="h-[15vh] bg-[#F7F7F7] ">
            <DemoNav />
          </div>
          {/*  Main Content */}
          <div className="w-full relative flex gap-6">
            <div className="w-10/12 h-[82vh] flow-hide">
              {/* end fof main content */}

              <div className="w-full grid grid-cols-2 gap-8 mt-6 mb-12  ">
                <div className="w-full overflow-x-auto bg-[#CFDBE3] flex flex-col">
                  <h1 className="font-bold text-lg text-center mt-3">
                    Income Chart
                  </h1>
                  <IncomeChart />
                </div>
                <div className="w-full overflow-x-auto flex flex-col bg-[#CFDBE3]">
                  <h1 className="font-bold text-lg text-center mt-3">
                    Expense Chart
                  </h1>
                  <ExpensesChart />
                </div>
              </div>
              <div className="w-full overflow-x-auto">

              <Stats />
              </div>
            </div>
            <BotContainer />
          </div>
        </div>
      </div>
    );
}

export default DemoContent
