
import Forcast from '../Charts/forcast-chart';


const Stats = () => {
  return (
    <div className="w-full bg-[#fff] flex flex-col p-4 rounded-[6px]">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <p className="text-lg text-[#0e0e0e] font-bold">Forcast</p>
        </div>
        {/* right side */}
        <div className="flex space-x-4">
          <button className="px-3 text-primary text-sm bg-[#F2F4F5] rounded-[6px] ">
            Current Period
          </button>
          <p className="text-secondary text-sm ">Quarter</p>
          <p className="text-secondary text-sm ">Year</p>
        </div>
      </div>
      {/* stats session */}
   
      <div className="w-full mt-8 overflow-x-auto  ">
        <Forcast />
      </div>
    </div>
  );
}

export default Stats
