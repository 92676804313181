import React from 'react'
import { useNavigate } from 'react-router-dom';
import InvoiceSidebar from '../../components/Sidebar/InvoiceSidebar'
import cancel from "../../assets/svgs/close-circle.svg";
import ArrowRight from "../../assets/svgs/arrow-right.svg"
import add from "../../assets/svgs/add.svg"

function InvoiceInformation() {
    const navigate = useNavigate();

    const HandleCancel = () => {
        navigate("/invoice")
    }

  return (
    <div className='w-full h-screen flex justify-between'>
        <InvoiceSidebar />
        <div className="w-[80%] pt-10">
            <div onClick={HandleCancel} className="w-11/12 m-auto flex justify-end items-center">
                <img
                    src={cancel}
                    alt="cancel icon"
                    className="hover:bg-slate-300 rounded-full p-3 cursor-pointer"
                />
            </div>

            <div className='w-3/5 m-auto mt-16'>
                <div className="flex flex-col items-start gap-2 self-stretch text-[#000000] mt-8">
                    <h3 className='text-[31.25px] font-bold not-italic leading-normal'>Your customer's information</h3>
                    <p className='text-[16px] not-italic font-normal leading-normal'>Enter the recipient's information to proceed</p>
                </div>

                {/* center */}
                <div className="w-full border-2 border-border rounded-[6px] py-2 mt-8 px-4 justify-between flex items-start">
                    <div className="w-[80%] border-2 border-border rounded-[6px] pl-2">
                        <p className='text-[#041D33] text-[14px] not-italic font-extralight leading-normal'>Customer name</p>
                        <p className='text-[#828282] text-[14px] not-italic font-medium leading-5 w-full h-10 mt-2 cursor-pointer'>Select existing customer</p>
                    </div>
                    <div className="bg-[#E8E8FF] flex items-center justify-center self-center py-3 rounded-md text-[#2F80EC] w-[17%] cursor-pointer">
                        <img src={add} alt="add icon" className='h-5' />
                        <p className="text-[14px] not-italic font-medium leading-normal text-right">Add</p>
                    </div>
                </div>
                <div className="w-full border-2 border-border rounded-[6px] py-2 mt-8 px-4 justify-between flex flex-col items-start">
                    <label htmlFor="" className='text-[#041D33] text-[14px] not-italic font-extralight leading-normal pl-2'>Customer email</label>
                    <div className="w-full">
                        <input type="text" placeholder='someone@email.com' className='text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none' />
                    </div>
                </div>
                {/* END of  center */}

                <div className="flex justify-between items-center bg-[#2F80EC] rounded-md w-[90px] h-10 px-2 text-center mt-5 cursor-pointer relative left-[80%]">
                    <p className='text-[#fff]'>Next</p>
                    <img src={ArrowRight} alt="arrow right icon" />
                </div>
            </div>
        </div>    
    </div>
  )
}

export default InvoiceInformation