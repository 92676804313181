import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { loginUser,login } from "../../features/auth/AuthSlice";
import { Link } from "react-router-dom";
const Login = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const handleSubmit = () => {
    const data = {
      email: email,
      password: password,
    };
    dispatch(login(data));
  };
  


  useEffect(() => {
    if (auth.token && auth?.userData?.user?.country === "nigeria") {
      window.location.replace("/dashboard");
    } else if (auth.token && auth?.userData?.user?.country !== "nigeria") {
      window.location.replace("/dashboard");
    }
  }, [auth?.token]);

  return (
    <div className="w-full relative flex justify-between min-h-screen bg-[#f8f8f8] overflow-x-hidden  ">
      <div className="w-5/12 flex flex-col relative">
        <div className="bg-primary rounded-full w-[1400px] h-[1400px] absolute left-[-800px] top-[-650px]  ">
          <h1 className="text-white font-bold text-[40px] absolute bottom-[30%]  right-[7%] text-center ">
            Welcome to Reelin.ai <br />
            <span className="text-base text-white">
              AI-Powered Insights and Financial Analytics in One Place
            </span>
          </h1>
        </div>
      </div>

      <div className="w-7/12 ">
        <div className="w-full  flex justify-center items-center flex-col  md:w-9/12  ">
          <div className=" flex flex-col  p-6 rounded-xl w-9/12 mx-auto h-screen justify-center">
            
          <h1 className="text-black text-3xl font-bold text-center ">Login</h1>

          <div className="flex flex-col my-3">
            <label className="text-slate-800 text-lg  mb-2">Email</label>
            <input
              type="text"
              className="xl:w-full border border-slate-200 px-4 h-[50px] rounded-lg placeholder:text-slate-500 text-base"
              onChange={(e: any) => setEmail(e.target.value)}
              />
          </div>
          <div className="flex flex-col my-3">
            <label className="text-slate-800 text-lg  mb-2">Password</label>
            <input
              type="text"
              className="xl:w-full border border-slate-200 px-4 h-[50px] rounded-lg placeholder:text-slate-500 text-base"
              onChange={(e: any) => setPassword(e.target.value)}
              />
          </div>
          <button
            className="bg-primary px-6 py-2 rounded-lg text-white w-fit mt-6"
            onClick={handleSubmit}
            >
            {auth?.loading ? "Loading...." : "Submit"}
          </button>
          <Link to="/register" className="text-blue-600 text-lg mt-6 ">
            Register
          </Link>
        </div>
      </div>
    </div>
            </div>
  );
};

export default Login;
