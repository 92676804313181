/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState,Dispatch,SetStateAction,ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { WhiteCancel } from "../../assets/svgs";
interface sideoverlayprops {
  open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    children: ReactNode;
    
}
export default function SideOverlay(
    {open,setOpen,children} : sideoverlayprops
) {
  

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                 {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
