import React, { useEffect } from 'react'
import { MainLogo } from '../../assets/svgs'
import arrow from "../../assets/svgs/arrow-left.svg";
import plaidIlus from "../../assets/png/plaid-ilus.png"
import { Link, useNavigate } from 'react-router-dom';
import { Btn } from '../../components/Button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import SimplePlaidLink from '../../components/plaid/PlaidComponent';
import { getToken, resetSavedPlaid } from '../../features/auth/AuthSlice';
const ConnectPlaid = () => {
     const dispatch = useAppDispatch();
     const navigate = useNavigate();
    const auth = useAppSelector((state) => state.auth);
    useEffect(() => {
      dispatch(getToken());
      dispatch(resetSavedPlaid());

    }, []);
  
  useEffect(() => {
    if (auth?.plaidData) {
      
      navigate("/choose-account");
    }
  }, [auth?.plaidData]);
  
  return (
    <div className="w-full min-h-screen flow-hide  flex flex-col items-center justify-center relative pb-10 bg-background">
      <div className=" flex justify-center items-center">
        <MainLogo />
      </div>

      <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border bg-white border rounded-[8px] px-8 pt-8 pb-6 flex flex-col ">
        <div className="flex space-x-3 items-center cursor-pointer">
          <img src={arrow} alt="arrow" />
          <p className="text-[#9D9D9D] text-sm">Back</p>
        </div>
        <div className="flex flex-col mt-[35px]">
          <h1 className="text-black text-[31px] font-bold mb-8 ">
            Connect your bank <br /> account securely
          </h1>
          {/* <p className="text-black text-base font-normal mt-2 mb-8">
            Business Account
          </p> */}
          <p className="text-sm text-black">
            Unlock the full potential of Reelin. We analyze transactions for
            deeper insights only without the access to perform any transactions
            on your accounts. So it’s totally save 😉
          </p>
          <div className="mt-8 pb-12 flex items-center justify-center">
            <img src={plaidIlus} alt="" />
          </div>
          <div className="mt-8 w-full flex justify-end items-center ">
          <SimplePlaidLink />
          </div>
          <div className="mt-8 w-full flex justify-center space-x-1 items-center ">
            <p className="text-base text-secondary font-medium">
              Already have an account?
            </p>
            <Link
              to="/login"
              className="text-base text-customBlack font-medium"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectPlaid
