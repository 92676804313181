import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../assets/svgs/arrow-left.svg"
import vlogo from "../../assets/svgs/v-logo.svg";
import Security from "../../assets/svgs/security-user.svg"
import TickCircle from "../../assets/svgs/tick-circle.svg"
import Check from "../../assets/svgs/check.svg"
import Mobile from "../../assets/svgs/mobile.svg"
import Email from "../../assets/svgs/Email.svg"
import {MdArrowForwardIos} from "react-icons/md"

const PersonalSignup = () => {
  const navigate = useNavigate();
  const HandleBack = () => {
    navigate("/register")
  }
  
  return (
    <div className="w-full h-auto flex flex-col items-center justify-center">
      <div className="top-12 left-0 right-0 flex justify-center items-center my-10">
        <img src={vlogo} alt="vlogo" />
      </div>
      <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border border rounded-[8px] px-8 pt-8 pb-12 flex flex-col ">
      <figure onClick={HandleBack} className='flex items-center cursor-pointer'>
        <img src={ArrowLeft} alt="arrowleft icon" />
        <figcaption className='ml-2 text-[14px] font-normal not-italic text-[#2F80EC]'>Back</figcaption>
      </figure>

        <div className="flex flex-col items-start gap-2 self-stretch text-[#000000] mt-8">
          <h3 className='text-[31.25px] font-bold not-italic leading-normal'>Sign up</h3>
          <p className='text-[16px] not-italic font-normal leading-normal'>Personal Account</p>
        </div>
        
          {/* center */}
          <Link to="/personal/bvn" className="w-full border border-border rounded-[6px] py-6 mt-8 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <div className="flex items-center">
              <img src={TickCircle} alt="tick-circle icon" />
              <img src={Security} alt="security icon" className='mx-3' />
              <div className="flex flex-col">
                <h1 className="text-black font-medium text-xl ">BVN</h1>
                <p className="text-secondary text-sm">
                Create a personal account using BVN
                </p>
              </div>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A] items-end" />
          </Link>

          <Link to="/personal/nin" className="w-full border border-border rounded-[6px] py-6 mt-8 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <div className="flex items-center">
              <img src={TickCircle} alt="tick-circle icon" />
              <img src={Check} alt="check icon" className='mx-3' />
              <div className="flex flex-col">
                <h1 className="text-black font-medium text-xl ">NIN</h1>
                <p className="text-secondary text-sm">
                Create a personal account using NIN
                </p>
              </div>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A] items-end" />
          </Link>

          <Link to="/personal/number" className="w-full border border-border rounded-[6px] py-6 mt-8 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <div className="flex items-center">
              <img src={TickCircle} alt="tick-circle icon" />
              <img src={Mobile} alt="mobile icon" className='mx-3' />
              <div className="flex flex-col">
                <h1 className="text-black font-medium text-xl ">Phone Number</h1>
                <p className="text-secondary text-sm">
                Personal Account for Nigerian’s only 
                </p>
              </div>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A] items-end" />
          </Link>

          <Link to="/personal/email" className="w-full border border-border rounded-[6px] py-6 mt-8 px-4 items-center justify-between flex cursor-pointer hover:bg-[#EAF2FD] ">
            <div className="flex items-center">
              <img src={TickCircle} alt="tick-circle icon" />
              <img src={Email} alt="email icon" className='mx-3' />
              <div className="flex flex-col">
                <h1 className="text-black font-medium text-xl ">Email</h1>
                <p className="text-secondary text-sm">
                Personal Account for other countires
                </p>
              </div>
            </div>
            <MdArrowForwardIos className="text-[#8C8A8A] items-end" />
          </Link>
          {/* END of  center */}

           <div className="mt-8 w-full flex justify-center space-x-1 items-center ">
            <p className="text-base text-secondary font-medium">
              Already have an account?
            </p>
            <Link to="/login" className="text-base text-primary font-medium">
              Login
            </Link>
          </div>
      </div>
    </div>
  )
}

export default PersonalSignup
