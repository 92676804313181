import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../assets/svgs/arrow-left.svg"
import ArrowRight from "../../assets/svgs/arrow-right.svg"
import vlogo from "../../assets/svgs/v-logo.svg";
import SmallUser from "../../assets/svgs/small-user.svg"
import SMS from "../../assets/svgs/sms.svg"

function BusinessSignup() {
  const navigate = useNavigate();
  const HandleBack = () => {
    navigate("/register")
  }
    
  return (
    <div className="w-full h-auto flex flex-col items-center justify-center">
      <div className="top-12 left-0 right-0 flex justify-center items-center my-10">
        <img src={vlogo} alt="vlogo" />
      </div>
      <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border border rounded-[8px] px-8 pt-8 pb-12 flex flex-col">
        <figure onClick={HandleBack} className='flex items-center cursor-pointer'>
          <img src={ArrowLeft} alt="arrowleft icon" />
          <figcaption className='ml-2 text-[14px] font-normal not-italic text-[#2F80EC]'>Back</figcaption>
        </figure>
        <div className="flex flex-col items-start gap-2 self-stretch text-[#000000] mt-8">
          <h3 className='text-[31.25px] font-bold not-italic leading-normal'>Sign up</h3>
          <p className='text-[16px] not-italic font-normal leading-normal'>Business Account</p>
        </div>
      
        {/* center */}
        <div className="w-full border border-border rounded-[6px] mt-8 px-4 justify-between flex flex-col items-start cursor-pointer">
            <label htmlFor="" className='text-[#041D33] text-[14px] not-italic font-extralight leading-normal'>Business Name</label>
            <div className="relative w-full">
                <input type="text" placeholder='James' className='text-[#828282] text-[14px] not-italic font-medium leading-5 w-full h-10 outline-none' />
                <img src={SmallUser} alt="small user icon" className='absolute right-2 top-5' />
            </div>
        </div>

        <div className="w-full border border-border rounded-[6px] mt-8 px-4 justify-between flex flex-col items-start cursor-pointer">
            <label htmlFor="" className='text-[#041D33] text-[14px] not-italic font-extralight leading-normal'>Email</label>
            <div className="relative w-full">
              <input type="text" placeholder='example@mail.com' className='text-[#828282] text-[14px] not-italic font-medium leading-5 w-full h-10 outline-none' />
              <img src={SMS} alt="sms icon" className='absolute right-2 top-5' />
            </div>
        </div>
        {/* END of  center */}

        <div className='flex w-full justify-between mt-5 text-[#828282]'>
          <input type="radio" className="h-3 w-3 text-indigo-600 border-gray-300 focus:ring-indigo-500" />
          <p className='w-11/12 text-[12px] not-italic font-normal leading-normal'>I have read, understood and I agree to Allocate’s Privacy Policy, and Terms and conditions.</p>
        </div>

        <div className='flex w-full justify-between mt-5 text-[#828282]'>
          <input type="radio" className="h-3 w-3 text-indigo-600 border-gray-300 focus:ring-indigo-500" />
          <p className='w-11/12 text-[12px] not-italic font-normal leading-normal'>Join Versus Allocate business community for exclusive access to all our business resources and events to help you grow</p>
        </div>

        <div className="flex justify-between items-center bg-[#2F80EC] rounded-md w-[90px] h-10 px-2 text-center mt-5 cursor-pointer relative left-[80%]">
          <p className='text-[#fff]'>Next</p>
          <img src={ArrowRight} alt="arrow right icon" />
        </div>
      
        <div className="w-full flex justify-center space-x-1 items-center mt-8">
          <p className="text-base text-secondary font-medium">
          Already have an account?
          </p>
          <Link to="/login" className="text-base text-primary font-medium">
          Login
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BusinessSignup