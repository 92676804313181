import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../assets/svgs/arrow-left.svg"
import ArrowRight from "../../assets/svgs/arrow-right.svg"
import vlogo from "../../assets/svgs/v-logo.svg";
import InfoCircle from "../../assets/svgs/info-circle.svg"

function PersonalEmail() {
    const navigate = useNavigate();
    const HandleBack = () => {
      navigate("/personal")
    }
    
    return (
        <div className="w-full h-auto flex flex-col items-center justify-center">
            <div className="top-12 left-0 right-0 flex justify-center items-center my-10">
                <img src={vlogo} alt="vlogo" />
            </div>
            <div className="2xl:w-5/12 max-w-[505px] w-full lg:w-6/12 mx-auto border-border border rounded-[8px] px-8 pt-8 pb-12 flex flex-col">
                <figure onClick={HandleBack} className='flex items-center cursor-pointer'>
                    <img src={ArrowLeft} alt="arrowleft icon" />
                    <figcaption className='ml-2 text-[14px] font-normal not-italic text-[#2F80EC]'>Back</figcaption>
                </figure>
                <div className="flex flex-col items-start gap-2 self-stretch text-[#000000] mt-8">
                <h3 className='text-[31.25px] font-bold not-italic leading-normal'>Validate your email</h3>
                <p className='text-[16px] not-italic font-normal leading-normal'>Create an account using your emaIl</p>
                </div>
            
                {/* center */}
                <div className="w-full border border-border rounded-[6px] py-6 mt-8 px-4 justify-between flex flex-col items-start cursor-pointer hover:bg-[#EAF2FD] ">
                    <label htmlFor="" className='text-[#041D33] text-[14px] not-italic font-extralight leading-normal'>EMAIL</label>
                    <div className="relative w-full">
                        <input type="text" placeholder='someone@email.com' className='text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none' />
                        <img src={InfoCircle} alt="info circle" className='absolute right-2 top-6' />
                    </div>
                </div>
                {/* END of  center */}

                <Link to="/personal/email/verify-email" className="flex justify-between items-center bg-[#2F80EC] rounded-md w-[90px] h-10 px-2 text-center mt-5 cursor-pointer relative left-[80%]">
                  <p className='text-[#fff]'>Next</p>
                  <img src={ArrowRight} alt="arrow right icon" />
                </Link>
            
                <div className="w-full flex justify-center space-x-1 items-center mt-8">
                    <p className="text-base text-secondary font-medium">
                    Already have an account?
                    </p>
                    <Link to="/login" className="text-base text-primary font-medium">
                    Login
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PersonalEmail