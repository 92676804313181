import React from "react";

const CsvToPlainTextConverter = ({ setDataQues }: any) => {
  const convertCsvToPlainText = async (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const csvContent = e.target.result;
        const plainText = csvContent.replace(/,/g, " "); // Replace commas with spaces
        setDataQues(plainText);
        console.log("helelelelelel");
      };
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".csv,.xls"
        onChange={(e) => convertCsvToPlainText(e)}
      />
    </div>
  );
};

export default CsvToPlainTextConverter;
