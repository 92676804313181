import React from "react";
import BlueUp from "../../assets/svgs/blue-arrow-up.svg"
import arrowUp from "../../assets/svgs/arrow-up.svg";
import arrowDown from "../../assets/svgs/arrow-down.svg";
import DashboadTop from "./DashboadTop";


const Transaction = () => {

  return (
    <div className="w-[95%] h-auto float-right pt-10 pr-12">
      <DashboadTop />

      <div className="w-full mt-[72px] ">
        <h1 className="text-secondary font-bold text-[25px]">
          Account Tracking
        </h1>
        <div className="h-20 w-full mt-6 flex justify-between">
          <div className="bg-slate-400 w-[23%] h-20 flex text-white rounded-md">
            <div className="bg-[#073E92] w-3/5 h-20 rounded-l-md flex flex-col justify-center pl-3">
              <h1 className="text-[20px] not-italic font-semibold leading-normal">
                54%
              </h1>
              <p className="text-[12px] not-italic font-normal leading-normal">
                Debit
              </p>
            </div>
            <div className="bg-[#07367E] w-2/5 h-20 rounded-r-md flex flex-col justify-center pl-3">
              <p className="text-[10px] not-italic font-normal leading-normal">
                12
              </p>
              <p className="text-[10px] not-italic font-normal leading-normal">
                Transactions
              </p>
              <div className="flex items-center mt-1">
                <img src={BlueUp} alt="arrow-up icon" />
                <p className="text-[#2D9CED] text-[12px] not-italic font-normal leading-normal">
                  33%
                </p>
              </div>
            </div>
          </div>

          <div className="bg-slate-400 w-[23%] h-20 flex text-white rounded-md">
            <div className="bg-[#2D9CED] w-3/5 h-20 rounded-l-md flex flex-col justify-center pl-3">
              <h1 className="text-[20px] not-italic font-semibold leading-normal">
                26%
              </h1>
              <p className="text-[12px] not-italic font-normal leading-normal">
                Debit
              </p>
            </div>
            <div className="bg-[#07367E] w-2/5 h-20 rounded-r-md flex flex-col justify-center pl-3">
              <p className="text-[10px] not-italic font-normal leading-normal">
                12
              </p>
              <p className="text-[10px] not-italic font-normal leading-normal">
                Transactions
              </p>
              <div className="flex items-center mt-1">
                <img src={BlueUp} alt="arrow-up icon" />
                <p className="text-[#2D9CED] text-[12px] not-italic font-normal leading-normal">
                  10%
                </p>
              </div>
            </div>
          </div>

          <div className="bg-slate-400 w-[23%] h-20 flex text-white rounded-md">
            <div className="bg-[#073E92] w-3/5 h-20 rounded-l-md flex flex-col justify-center pl-3">
              <h1 className="text-[20px] not-italic font-semibold leading-normal">
                54%
              </h1>
              <p className="text-[12px] not-italic font-normal leading-normal">
                Debit
              </p>
            </div>
            <div className="bg-[#07367E] w-2/5 h-20 rounded-r-md flex flex-col justify-center pl-3">
              <p className="text-[10px] not-italic font-normal leading-normal">
                12
              </p>
              <p className="text-[10px] not-italic font-normal leading-normal">
                Transactions
              </p>
              <div className="flex items-center mt-1">
                <img src={BlueUp} alt="arrow-up icon" />
                <p className="text-[#2D9CED] text-[12px] not-italic font-normal leading-normal">
                  33%
                </p>
              </div>
            </div>
          </div>

          <div className="bg-slate-400 w-[23%] h-20 flex text-white rounded-md">
            <div className="bg-[#2D9CED] w-3/5 h-20 rounded-l-md flex flex-col justify-center pl-3">
              <h1 className="text-[20px] not-italic font-semibold leading-normal">
                26%
              </h1>
              <p className="text-[12px] not-italic font-normal leading-normal">
                Debit
              </p>
            </div>
            <div className="bg-[#07367E] w-2/5 h-20 rounded-r-md flex flex-col justify-center pl-3">
              <p className="text-[10px] not-italic font-normal leading-normal">
                12
              </p>
              <p className="text-[10px] not-italic font-normal leading-normal">
                Transactions
              </p>
              <div className="flex items-center mt-1">
                <img src={BlueUp} alt="arrow-up icon" />
                <p className="text-[#2D9CED] text-[12px] not-italic font-normal leading-normal">
                  10%
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-[72px] mb-20 ">
        <h1 className="text-secondary font-bold text-[25px]">Transactions</h1>
        <div className="w-full mt-6 flex flex-col space-y-2">
          {/* one side */}
          <div className="flex bg-[#F2F4F5] w-full justify-between items-center p-3">
            {/* left */}
            <div className="flex items-center space-x-4">
              <img src={arrowUp} alt="arrow" />
              <div className="flex flex-col">
                <h1 className="text-black not-italic text-[25px] font-semibold leading-normal">
                  Kwatmi Haruna Paul
                </h1>
                <p className="text-[18px] not-italic font-medium leading-normal text-[#717E95] ">
                  Sent 12 Mar 2023
                </p>
              </div>
            </div>
            {/* right */}
            <h1 className="text-[#0e0e0e] text-[25px] font-semibold">32,123</h1>
          </div>
          {/* end of it */}
          {/* one side */}
          <div className="flex bg-[#fff] w-full justify-between items-center p-3">
            {/* left */}
            <div className="flex items-center space-x-4">
              <img src={arrowUp} alt="arrow" />
              <div className="flex flex-col">
                <h1 className="text-black not-italic text-[25px] font-semibold leading-normal">
                  Kwatmi Haruna Paul
                </h1>
                <p className="text-[18px] not-italic font-medium leading-normal text-[#717E95] ">
                  Sent 12 Mar 2023
                </p>
              </div>
            </div>
            {/* right */}
            <h1 className="text-[#0e0e0e] text-[25px] font-semibold">32,123</h1>
          </div>
          {/* end of it */}
          {/* one side */}
          <div className="flex bg-[#fff] w-full justify-between items-center p-3">
            {/* left */}
            <div className="flex items-center space-x-4">
              <img src={arrowDown} alt="arrow" />
              <div className="flex flex-col">
                <h1 className="text-black not-italic text-[25px] font-semibold leading-normal">
                  Ibrahim Musa Damisa
                </h1>
                <p className="text-[18px] not-italic font-medium leading-normal text-[#717E95] ">
                  Sent 12 Mar 2023
                </p>
              </div>
            </div>
            {/* right */}
            <h1 className="text-[#0e0e0e] text-[25px] font-semibold">32,123</h1>
          </div>
          {/* end of it */}
        </div>
      </div>
    </div>
  );
};

export default Transaction;
