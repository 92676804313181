import React from 'react'
import { useNavigate } from 'react-router-dom';
import InvoiceSidebar from '../../components/Sidebar/InvoiceSidebar'
import cancel from "../../assets/svgs/close-circle.svg";
import ArrowRight from "../../assets/svgs/arrow-right.svg";
import down from "../../assets/svgs/down.svg";
import calender from "../../assets/svgs/calendar-search.svg"
import Add from "../../assets/svgs/add.svg";

function InvoiceDetails() {
    const navigate = useNavigate();

    const HandleCancel = () => {
        navigate("/invoice")
    }

  return (
    <div className="w-full h-screen flex justify-between not-italic">
      <InvoiceSidebar />
      <div className="w-[80%] pt-3">
        <div
          onClick={HandleCancel}
          className="w-11/12 m-auto flex justify-end items-center"
        >
          <img
            src={cancel}
            alt="cancel icon"
            className="hover:bg-slate-300 rounded-full p-3 cursor-pointer"
          />
        </div>

        <div className="w-3/5 m-auto mt-5">
          <div className="flex flex-col items-start gap-2 self-stretch text-[#000000] mt-2">
            <h3 className="text-[31.25px] font-bold not-italic leading-normal">
              Invoice details
            </h3>
            <p className="text-[16px] not-italic font-normal leading-normal">
              Enter the following details to finish creating your invoice
            </p>
          </div>

          {/* center */}
          <div className="w-full mt-8 px-4 justify-between flex flex-col items-start">
            <div className="w-full">
              <label
                htmlFor=""
                className="text-[#041D33] text-[14px] not-italic font-extralight leading-normal"
              >
                Invoice Title
              </label>
              <input
                type="text"
                placeholder="Enter Title of Invoice"
                className="text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none border-2 border-border rounded-[6px]"
              />
            </div>

            <div className="w-full flex justify-between mt-5">
              <div className="w-[49%]">
                <label
                  htmlFor=""
                  className="text-[#041D33] text-[14px] not-italic font-extralight leading-normal"
                >
                  Currency
                </label>
                <div className="w-full relative">
                  <input
                    type="text"
                    placeholder="$"
                    className="text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none border-2 border-border rounded-[6px]"
                  />
                  <img
                    src={down}
                    alt="down icon"
                    className="absolute right-2 top-6"
                  />
                </div>
              </div>

              <div className="w-[49%]">
                <label
                  htmlFor=""
                  className="text-[#041D33] text-[14px] not-italic font-extralight leading-normal"
                >
                  Due Date
                </label>
                <div className="w-full relative">
                  <input
                    type="text"
                    placeholder="Select date"
                    className="text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none border-2 border-border rounded-[6px]"
                  />
                  <img
                    src={calender}
                    alt="calender icon"
                    className="absolute right-2 top-6"
                  />
                </div>
              </div>
            </div>

            <p className="text-[12px] font-normal text-[#818181]">
              Your account details will be attached to the invoice
            </p>

            <div className="w-full flex justify-between mt-5">
              <div className="w-[48%] flex justify-between">
                <div className="w-[70%]">
                  <label
                    htmlFor=""
                    className="text-[#041D33] text-[14px] not-italic font-extralight leading-normal"
                  >
                    Item description
                  </label>
                  <input
                    type="text"
                    placeholder="Enter item description"
                    className="text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none border-2 border-border rounded-[6px]"
                  />
                </div>

                <div className="w-[25%]">
                  <label
                    htmlFor=""
                    className="text-[#041D33] text-[14px] not-italic font-extralight leading-normal"
                  >
                    {" "}
                    Quantity
                  </label>
                  <input
                    type="text"
                    placeholder="1"
                    className="text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none border-2 border-border rounded-[6px]"
                  />
                </div>
              </div>

              <div className="w-[48%] flex justify-between">
                <div className="w-[48%]">
                  <label
                    htmlFor=""
                    className="text-[#041D33] text-[14px] not-italic font-extralight leading-normal"
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    placeholder="0"
                    className="text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none border-2 border-border rounded-[6px]"
                  />
                </div>

                <div className="w-[48%]">
                  <label
                    htmlFor=""
                    className="text-[#041D33] text-[14px] not-italic font-extralight leading-normal"
                  >
                    Amount
                  </label>
                  <input
                    type="text"
                    placeholder="0"
                    className="text-[#828282] text-[14px] not-italic font-medium leading-5 pl-2 w-full h-10 mt-2 outline-none border-2 border-border rounded-[6px]"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* END of  center */}
          <div className="bg-[#E8E8FF] text-[#2F80EC] h-10 w-4/12 mt-5 ml-3 flex items-center justify-center rounded-md cursor-pointer">
            <img src={Add} alt="add icon" className="h-4" />
            <p className="text-[14px] not-italic font-medium leading-normal text-right">
              Add additional item
            </p>
          </div>

          <div
            className="flex justify-between items-center bg-[#2F80EC] rounded-md w-[90px] h-10 px-2 text-center mt-5 cursor-pointer relative left-[80%] "
            onClick={() => navigate("/invoice/Invoiceinformation")}
          >
            <p className="text-[#fff]">Next</p>
            <img src={ArrowRight} alt="arrow right icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceDetails