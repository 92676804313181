import axios from "axios";
import { baseUrl, botUrl } from "./endpoints";

const APIService = axios.create({
  baseURL: baseUrl,
});

export default APIService;
export const nAPIService = (method: any, address: any, header: any) => {
  return axios({
    method: method,
    url: `${baseUrl}/${address}`,
    headers: header,
  });
};

export const BotAPIService = axios.create({
  baseURL: botUrl,
});


