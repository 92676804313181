import attach from "../../assets/svgs/attach-square.svg";
import maximize from "../../assets/svgs/maximize-2.svg";
import layoutIcon from "../../assets/svgs/layout.svg"
import { SendIcon } from "../../assets/svgs";
import { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { resetQues, sendMessage } from "../../features/auth/AuthSlice";
import { singleMessageProps } from "../Demo/DemoNav";
import loading from "../../assets/png/loading-animation.gif";
import { CopyIcon, RotateIcon } from "../../assets/svgs";
import { toast } from "react-toastify";
import CsvToPlainTextConverter from "../Convert/FileConvert";
import ReUseModal from "../Modal/ReUseModal";

const BotContainer = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const allResp = [...auth?.allMessages].reverse();

  const [allMessages, setAllMessages] = useState<singleMessageProps[]>(allResp);

  const handleNewAnswer = (newAnswer: singleMessageProps) => {
    const rev = [...allMessages, newAnswer].reverse();
    setAllMessages(rev);
  };

  const [showRes, setShowRes] = useState(false);
    const [open, setOpen] = useState(false);
  const [resp, setResp] = useState<any>({});
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [dataQues, setDataQues] = useState<any>("");
  const [sentQues, setSentQues] = useState("");
  useEffect(() => {
    if (!auth.token) {
      window.location.replace("/login");
    }
  }, []);

  const revampQues = `Based on the uploaded data ${question}`;
  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlePost = () => {
    setSentQues(question);
    dispatch(resetQues());
    setAnswer("");
    const data = {
      body: dataQues ? dataQues : revampQues,
      type: dataQues ? "csv" : "text",
    };
    dispatch(sendMessage(data));
  };
  useEffect(() => {
    if (question) {
      setDataQues("");
    }
  }, [question]);

  useEffect(() => {
    if (auth?.searchResult?.body !== undefined || null) {
      setShowRes(true);
      setAnswer(auth?.searchResult?.body);
      setResp(auth?.searchResult);
      handleNewAnswer(auth?.searchResult);
      scrollToBottom();
    
    } else {
      setShowRes(false);
    }
  }, [auth?.searchResult]);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handlePost();
    
    }
  };
  const handleRegenerate = () => {
    
  
     handlePost();
  
  }
   

    const copyToClipboard = () => {
      navigator.clipboard
        .writeText(answer)
        .then(() => {
          toast.success("Copied ")
        })
        .catch((err) => {
          console.error("Failed to copy:", err);
        });
    };

  return (
    <div className="min-w-[343px] max-w-[343px] bg-white max-h-[621px]  min-h-[82vh] mt-0 rounded-[12px] relative flex flex-col">
      <div className="w-full bg-greyish sticky top-0 left-0 right-0 h-16 rounded-t-[12px] flex items-center px-3 justify-between  ">
        <div className="gap-4 items-center flex">
          <span className="bg-black w-6 h-6 rounded-full"></span>
          <p className="text-lg font-medium text-customBlack">
            Reelin Assistant
          </p>
        </div>
        <div className="gap-4 items-center flex justify-end">
          <span>
            <img src={layoutIcon} alt="" />
          </span>
          <span>
            <img src={maximize} alt="" />
          </span>
        </div>
      </div>
      {/* message side */}
      <div className="w-full px-3 py-3 h-[480px] overflow-y-auto flex flex-col">
        {sentQues && (
          <div className="w-full flex space-x-3 pb-8">
            <span className="w-[24px] min-w-[24px] rounded-full bg-yellowish h-[24px]" />
            <div
              className={`flex-grow flex items-center flex-col space-y-3 p-3 bg-[#F2F4F5]`}
            >
              <p className="text-black text-base">{sentQues}</p>
              <span className="text-sm text-[#818181]">
                {new Date().toLocaleString()}
              </span>
            </div>
          </div>
        )}

        {/* response chats */}
        {answer && (
          <div className="flex flex-col gap-y-2 pb-8">
            <div className="w-full flex space-x-3 ">
              <span className="w-[24px] min-w-[24px] rounded-full bg-[#000000] h-[24px]" />
              <div
                className={`flex-grow flex items-center flex-col space-y-3 p-3 ${
                  resp.fromChat ? "bg-yellowish" : "bg-[#F2F4F5]"
                }`}
              >
                <p className="text-black text-base">{answer}</p>
                <span className="text-sm text-[#818181]">
                  {new Date().toLocaleString()}
                </span>
              </div>
            </div>
            <div className="flex justify-end items-center gap-3 ">
              <div
                className="bg-[#f2f4f5] w-20 h-8 rounded-[31px] flex items-center justify-center gap-2 cursor-pointer"
                onClick={copyToClipboard}
              >
                <span>
                  <CopyIcon />
                </span>
                <p className="text-black text-xs ">copy</p>
              </div>
              <div
                className="bg-[#f2f4f5] w-[117px] h-8 rounded-[31px] flex items-center justify-center gap-2 cursor-pointer"
                onClick={handleRegenerate}
              >
                <span>
                  <RotateIcon />
                </span>
                <p className="text-black text-xs ">Regenerate</p>
              </div>
            </div>
          </div>
        )}
        {auth?.loading && (
          <div className="mt-6">
            <img src={loading} alt="loader" />
          </div>
        )}
      </div>
      {/* end of message side */}
      {/* search and upload containers */}
      <div className=" flex flex-col px-3 ">
        <div className="w-full h-[51px] bg-[#f1f1f1] rounded-[8px] flex items-center px-3">
          <input
            type="text"
            className="border-none bg-transparent outline-none focus:border-none focus:outline-none text-black placeholder:text-sm text-sm flex-grow   "
            placeholder="What can the assitant help you with? "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setQuestion(e.target.value)
            }
            onKeyDown={handleKeyPress}
          />

          <button
            className="outline-none border-none w-2/12 flex justify-end cursor-pointer"
            onClick={handlePost}
          >
            <SendIcon />
          </button>
        </div>
        <div className="w-full flex items-center justify-between h-[51px]">
          <div className="flex space-x-2 items-center">
            <span>
              <img src={attach} alt="attach" />
            </span>
            <p className="text-black text-xs">Document uploaded.csv</p>
          </div>
          <div className="flex w-3/12 relative">
            {/* <input
              type="file"
              name="upload"
              id=""
              className="absolute inset-0 opacity-0"
            /> */}
            <p className="text-black text-xs underline cursor-pointer" onClick={() => setOpen(true)} >Upload now</p>
          </div>
        </div>
      </div>
      {/* end of search and upload containers  */}
      <ReUseModal open={open} setOpen={setOpen}>
        <div className="w-full flex flex-col ">
          <p>Click Below to upload your file</p>
          <CsvToPlainTextConverter setDataQues={setDataQues} />
          <div className="mt-4">
            <button
              className="text-white bg-blue-500 w-auto px-6 py-2 rounded-xl"
              onClick={handlePost}
            >
              {auth?.loading ? "Loading..." : "Upload File"}
            </button>
          </div>
        </div>
      </ReUseModal>
      
    </div>
  );
};


export default BotContainer;
